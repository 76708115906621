import React, { useState } from 'react';
import './Settings.css';  
import fullscreenIcon from '../../images/fullscreen.png';
import muteIcon from '../../images/mute.png';
import volumeIcon from '../../images/volume.png';

const Settings = ({ isOpen, onClose, volume, onVolumeChange, onToggleMute, isMuted, onToggleFullscreen }) => {
    if (!isOpen) return null;
  
    return (
      <div className="settings-modal-overlay" onClick={onClose}>
        <div className="settings-modal" onClick={(e) => e.stopPropagation()}>
          <h2>Settings</h2>
          <div className="settings-content">
  
            {/* Fullscreen Button */}
            <div className="settings-row">
              <button className="icon-button" onClick={onToggleFullscreen}>
                <img src={fullscreenIcon} alt="Fullscreen" className="settings-icon" />
                <span className="fullscreen-text">Fullscreen</span>
              </button>
            </div>
  
            {/* Mute/Volume Control */}
            <div className="settings-row">
              <button className="icon-button" onClick={onToggleMute}>
                <img src={isMuted ? muteIcon : volumeIcon} alt="Mute" className="settings-icon" />
              </button>
              <input
                type="range"
                id="volume-control"
                min="0"
                max="1"
                step="0.01"
                value={isMuted ? 0 : volume}
                onChange={(e) => onVolumeChange(e.target.value)}
                disabled={isMuted} // Disable slider if muted
              />
              <span className="volume-percentage">
                {isMuted ? '0%' : `${Math.round(volume * 100)}%`}
              </span>
            </div>
  
            {/* Close Button */}
            <button className="close-btn" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  export default Settings;