import React from 'react';
import './PolicyModal.css';
import policyImage from '../../images/policymodal.png'; 
import closeIcon from '../../images/close-icon.png'; // Assuming you have a close icon image

const PolicyModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="policy-modal-overlay" onClick={onClose}>
      <div className="policy-modal" onClick={(e) => e.stopPropagation()}>
        {/* Close Button */}
        <button className="close-modal-btn" onClick={onClose}>
          <img src={closeIcon} alt="Close" className="close-icon" />
        </button>

        {/* Policy Image */}
        <img src={policyImage} alt="Policy Modal" className="policy-image" />

        {/* Clickable Links on Image */}
        <div className="modal-links">
          <a href="#contact" className="modal-link">Contact</a>
          <a href="#privacy-policy" className="modal-link">Privacy Policy</a>
          <a href="#terms-of-service" className="modal-link">Terms of Service</a>
          <a href="#kyc-aml" className="modal-link">KYC/AML</a>
          <a href="#sweepstakes-rules" className="modal-link">Sweepstakes Rules</a>
        </div>
      </div>
    </div>
  );
};

export default PolicyModal;