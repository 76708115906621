import React, { useEffect, useRef } from 'react';
import hotData from '../../images/hot.json'; // Importing hot.json
import hotImage from '../../images/hot.png'; // Importing hot sprite sheet

function drawHotFrame(ctx, frameIndex) {
    const frame = hotData.frames[frameIndex].frame;
    const img = new Image();
    img.src = hotImage;

    img.onload = () => {
      ctx.clearRect(0, 0, frame.w, frame.h);
      ctx.drawImage(
        img,
        frame.x, frame.y, frame.w, frame.h,
        0, 0, frame.w, frame.h
      );
    };
}

const HotAnimation = ({ startFrame = 0 }) => {
  const canvasRef = useRef(null);
  let frameIndex = startFrame;

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    const animateHot = () => {
      drawHotFrame(ctx, frameIndex);
      frameIndex = (frameIndex + 1) % hotData.frames.length;
    };

    const interval = setInterval(animateHot, 100);

    return () => clearInterval(interval);
  }, [frameIndex, startFrame]);

  return (
    <canvas
      ref={canvasRef}
      className="hot-animation" // Apply the updated CSS class for positioning
      width={100}  // Adjust width
      height={50}  // Adjust height
    />
  );
};

export default HotAnimation;