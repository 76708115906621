import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../AuthContext';

export const HomePage = () => {
    const { login } = useContext(AuthContext);
    const [contentVisible, setContentVisible] = useState(true);
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    useEffect(() => {
        const loadCSS = (href) => {
            const link = document.createElement('link');
            link.rel = 'stylesheet';
            link.href = href;
            document.head.appendChild(link);
        };

        const cssFiles = [
            'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css',
            'https://code.jquery.com/ui/1.12.1/themes/base/jquery-ui.css',
            'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css',
            'https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/magnific-popup.min.css',
            'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css',
            'assets/css/style.css',
        ];

        cssFiles.forEach(loadCSS);

        const loadScript = (src) => {
            return new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = src;
                script.onload = resolve;
                document.body.appendChild(script);
            });
        };

        const jsFiles = [
            'assets/js/plugin/counter.js',
            'assets/js/plugin/plugin.js',
            'assets/js/main.js',
            'https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js',
            'https://code.jquery.com/jquery-3.6.0.min.js',
            'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js',
            'https://cdnjs.cloudflare.com/ajax/libs/waypoints/4.0.1/jquery.waypoints.min.js',
            'https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js',
            'https://cdnjs.cloudflare.com/ajax/libs/wow/1.1.2/wow.min.js',
            'assets/js/plugin/slick.js',
        ];

        const loadScriptsInOrder = async () => {
            for (const file of jsFiles) {
                await loadScript(file);
            }
            setContentVisible(true);
        };

        loadScriptsInOrder();

        return () => {
            cssFiles.forEach((file) => {
                const link = document.querySelector(`link[href="${file}"]`);
                if (link) link.remove();
            });
            jsFiles.forEach((file) => {
                const script = document.querySelector(`script[src="${file}"]`);
                if (script) script.remove();
            });
        };
    }, []);

    useEffect(() => {
        const handleLoad = () => {
            setContentVisible(true);
        };

        window.addEventListener('load', handleLoad);
        return () => window.removeEventListener('load', handleLoad);
    }, []);
    return (
        <>
            <meta charSet="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
            <link
                rel="shortcut icon"
                href="assets/images/fav.png"
                type="image/x-icon"
            />
            <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
            <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
            <link rel="stylesheet" href="assets/css/jquery-ui.css" />
            <link rel="stylesheet" href="assets/css/plugin/nice-select.css" />
            <link rel="stylesheet" href="assets/css/plugin/magnific-popup.css" />
            <link rel="stylesheet" href="assets/css/plugin/slick.css" />
            <link rel="stylesheet" href="assets/css/ara-font.css" />
            <link rel="stylesheet" href="assets/css/plugin/animate.css" />
            <link rel="stylesheet" href="assets/css/style.css" />

            {/* Scroll To Top Start*/}
            <a href="#" className="scrollToTop" onClick={(e) => {
                e.preventDefault();
                scrollToTop();
            }}>            <i className="fas fa-angle-double-up" />
            </a>

            {/* header-section start */}
            <header className="header-section" style={{ visibility: contentVisible ? 'visible' : 'hidden' }}>

                <div className="overlay">
                    <div className="container">
                        <div className="row d-flex header-area">
                            <nav className="navbar navbar-expand-lg navbar-light">
                                <a className="logoImg navbar-brand" href="/home">
                                    <img
                                        src="assets/images/fav.png"
                                        className="fav d-none d-lg-block d-xl-none"
                                        alt="fav"
                                    />
                                    <img
                                        src="assets/images/usmedium.png"
                                        className="logo d-block d-lg-none d-xl-block"
                                        alt="logo"
                                    />
                                </a>
                                <button
                                    className="navbar-toggler collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbar-content"
                                >
                                    <i className="fas fa-bars" />
                                </button>
                                <div
                                    className="collapse navbar-collapse justify-content-between"
                                    id="navbar-content"
                                >
                                    <div className="right-area header-action d-flex align-items-center max-un">
                                        <button
                                            type="button"
                                            className="login"
                                            data-bs-toggle="modal"
                                            data-bs-target="#loginMod"
                                        >
                                            Sign In
                                        </button>
                                        <button
                                            type="button"
                                            className="cmn-btn reg"
                                            data-bs-toggle="modal"
                                            data-bs-target="#loginMod"
                                        >
                                            Sign Up
                                        </button>
                                    </div>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
            {/* header-section end */}
            {/* Login Registration start */}
            <div className="log-reg" style={{ visibility: contentVisible ? 'visible' : 'hidden' }}>

                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="modal fade" id="loginMod">
                                <div className="modal-dialog modal-dialog-centered modal-lg"> {/* Updated to modal-lg for larger forms */}
                                    <div className="modal-content">
                                        <div className="modal-header justify-content-center">
                                            <button
                                                type="button"
                                                className="btn-close"
                                                data-bs-dismiss="modal"
                                                aria-label="Close"
                                            />
                                        </div>
                                        <ul className="nav log-reg-btn d-none justify-content-around">
                                            <li className="bottom-area" role="presentation">
                                                <button
                                                    className="nav-link"
                                                    id="regArea-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#regArea"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="regArea"
                                                    aria-selected="false"
                                                >
                                                    SIGN UP
                                                </button>
                                            </li>
                                            <li className="bottom-area" role="presentation">
                                                <button
                                                    className="nav-link active"
                                                    id="loginArea-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#loginArea"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="loginArea"
                                                    aria-selected="true"
                                                >
                                                    LOGIN
                                                </button>
                                            </li>
                                        </ul>
                                        <div className="tab-content">
                                            {/* Login Section */}
                                            <div
                                                className="tab-pane fade show active"
                                                id="loginArea"
                                                role="tabpanel"
                                                aria-labelledby="loginArea-tab"
                                            >
                                                <div className="login-reg-content">
                                                    <div className="modal-body overflow-auto" style={{ maxHeight: '80vh' }}> {/* Modal body made scrollable */}
                                                        <div className="form-area">
                                                            <form
                                                                onSubmit={async (e) => {
                                                                    e.preventDefault();
                                                                    const username_or_email = e.target.usernameOrEmail.value;
                                                                    const password = e.target.password.value;

                                                                    try {
                                                                        const response = await fetch("https://api.ultraspin.vip/api/login", {
                                                                            method: "POST",
                                                                            headers: {
                                                                                "Content-Type": "application/json",
                                                                            },
                                                                            body: JSON.stringify({
                                                                                username_or_email,
                                                                                password,
                                                                            }),
                                                                        });

                                                                        const data = await response.json();
                                                                        console.log(data);

                                                                        if (data.authToken) {
                                                                            login(data.authToken);
                                                                            alert("Login successful");
                                                                            window.location.href = "/lobby";
                                                                        } else {
                                                                            if (data.detail) {
                                                                                alert("Login failed: " + data.detail);
                                                                            } else {
                                                                                alert("Login failed, no token returned.");
                                                                            }
                                                                        }
                                                                    } catch (error) {
                                                                        console.error("Login error: ", error);
                                                                        alert("Error: " + error.message);
                                                                    }
                                                                }}
                                                            >
                                                                <div className="section-text text-center">
                                                                    <h3 className="title">Welcome Back</h3>
                                                                    <p>
                                                                        Not a member?{" "}
                                                                        <a href="#" className="reg-btn">
                                                                            Register
                                                                        </a>
                                                                    </p>
                                                                </div>

                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input
                                                                        type="text"
                                                                        name="usernameOrEmail"
                                                                        placeholder="Username or Email"
                                                                        required
                                                                    />
                                                                </div>

                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/security.png" alt="icon" />
                                                                    <input
                                                                        type="password"
                                                                        name="password"
                                                                        placeholder="Password"
                                                                        required
                                                                    />
                                                                </div>

                                                                <div className="checkbox-item d-flex justify-content-between">
                                                                    <label className="checkbox-single d-flex align-items-center">
                                                                        <span className="content-area">
                                                                            <span className="checkbox-area d-flex">
                                                                                <input type="checkbox" />
                                                                                <span className="checkmark" />
                                                                            </span>
                                                                            <span className="item-title d-flex align-items-center">
                                                                                <span>Remember me</span>
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                    <a href="#">Forgot Password</a>
                                                                </div>

                                                                <div className="btn-area text-center" id='LoginBTN'>
                                                                    <button type="submit" className="cmn-btn mt-4">
                                                                        Login
                                                                        <i className="icon-d-right-arrow-2" />
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Registration Section */}
                                            <div
                                                className="tab-pane fade"
                                                id="regArea"
                                                role="tabpanel"
                                                aria-labelledby="regArea-tab"
                                            >
                                                <div className="login-reg-content regMode">
                                                    <div className="modal-body overflow-auto" style={{ maxHeight: '80vh' }}> {/* Scrollable modal */}
                                                        <div className="form-area">
                                                            <form
                                                                onSubmit={async (e) => {
                                                                    e.preventDefault();
                                                                    const firstName = e.target.firstName.value;
                                                                    const lastName = e.target.lastName.value;
                                                                    const dateOfBirth = e.target.dateOfBirth.value;
                                                                    const phoneNumber = e.target.phoneNumber.value;
                                                                    const streetAddress = e.target.streetAddress.value;
                                                                    const city = e.target.city.value;
                                                                    const state = e.target.state.value;
                                                                    const zipCode = e.target.zipCode.value;
                                                                    const email = e.target.email.value;
                                                                    const username = e.target.username.value;
                                                                    const password = e.target.password.value;
                                                                    const affiliateId = e.target.affiliateId.value || null;
                                                                    const marketingOptIn = e.target.marketingOptIn.checked; // Handling the marketing opt-in checkbox

                                                                    try {
                                                                        const response = await fetch("https://api.ultraspin.vip/api/register", {
                                                                            method: "POST",
                                                                            headers: {
                                                                                "Content-Type": "application/json",
                                                                            },
                                                                            body: JSON.stringify({
                                                                                username,
                                                                                email,
                                                                                password,
                                                                                first_name: firstName,
                                                                                last_name: lastName,
                                                                                date_of_birth: dateOfBirth,
                                                                                phone_number: phoneNumber,
                                                                                street_address: streetAddress,
                                                                                city,
                                                                                state,
                                                                                zip_code: zipCode,
                                                                                affiliate_id: affiliateId,
                                                                                marketing_opt_in: marketingOptIn,  // Including marketing opt-in in the request
                                                                            }),
                                                                        });
                                                                        const data = await response.json();
                                                                        if (response.ok) {
                                                                            alert("Registration successful");
                                                                        } else {
                                                                            alert("Registration failed: " + data.detail);
                                                                        }
                                                                    } catch (error) {
                                                                        alert("Error: " + error.message);
                                                                    }
                                                                }}
                                                            >
                                                                <div className="section-text text-center">
                                                                    <h5 className="sub-title">Ready to get started?</h5>
                                                                    <h3 className="title">Welcome Bonus +50% SS!</h3>
                                                                    <p>
                                                                        Already have an account?{" "}
                                                                        <a href="#" className="log-btn">
                                                                            Login
                                                                        </a>
                                                                    </p>
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input type="text" name="firstName" placeholder="First Name" required />
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input type="text" name="lastName" placeholder="Last Name" required />
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input type="date" name="dateOfBirth" placeholder="Date of Birth" required />
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input type="text" name="phoneNumber" placeholder="Phone Number" required />
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input type="text" name="streetAddress" placeholder="Street Address" required />
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input type="text" name="city" placeholder="City" required />
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input type="text" name="state" placeholder="State" required />
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input type="text" name="zipCode" placeholder="ZIP Code" required />
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input type="email" name="email" placeholder="Email" required />
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input type="text" name="username" placeholder="Username" required />
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/security.png" alt="icon" />
                                                                    <input type="password" name="password" placeholder="Password" required />
                                                                </div>
                                                                <div className="input-area d-flex align-items-center">
                                                                    <img src="assets/images/icon/email-icon.png" alt="icon" />
                                                                    <input type="text" name="affiliateId" placeholder="Affiliate ID (optional)" />
                                                                </div>
                                                                <div className="checkbox-item">
                                                                    <label className="checkbox-single d-flex align-items-center">
                                                                        <span className="content-area">
                                                                            <span className="checkbox-area d-flex">
                                                                                <input type="checkbox" defaultChecked="checked" />
                                                                                <span className="checkmark" />
                                                                            </span>
                                                                            <span className="item-title d-flex align-items-center">
                                                                                <span>
                                                                                    I am at least 18 years of age and I have read, accepted, and agreed to the{" "}
                                                                                    <u>
                                                                                        <a href="#">Privacy Policy</a>, T&amp;C,{" "}
                                                                                        <a href="#">Terms and Conditions</a>
                                                                                    </u>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                    <label className="checkbox-single d-flex align-items-center">
                                                                        <span className="content-area">
                                                                            <span className="checkbox-area d-flex">
                                                                                <input type="checkbox" name="marketingOptIn" /> {/* Added name for this checkbox */}
                                                                                <span className="checkmark" />
                                                                            </span>
                                                                            <span className="item-title d-flex align-items-center">
                                                                                <span>I want to receive promotions by e-mail.</span>
                                                                            </span>
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                                <div className="btn-area text-center" id='SIGNUPBTN'>
                                                                    <button type="submit" className="cmn-btn mt-4">
                                                                        Get Started Now
                                                                        <i className="icon-d-right-arrow-2" />
                                                                    </button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* End Registration Section */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Login Registration end */}
            {/* Banner Section start */}
            <section className="banner-section index">
                <div className="overlay">
                    <div className="banner-content">
                        <div className="container wow fadeInUp">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-xl-6 col-lg-6 col-md-8">
                                    <div className="main-content">
                                        <div className="top-area section-text">
                                            <h5 className="sub-title-top"> </h5>
                                            <h1 className="title-top">
                                                <span></span>
                                            </h1>
                                            <p className="xlr-top">
                                                <br></br>
                                                <br></br>
                                            </p>
                                            <div className="btn-area mt-30">
                                                <a
                                                    href="#"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#loginMod"
                                                    className="cmn-btn reg"
                                                    onClick={(e) => e.preventDefault()} // Prevent the default anchor behavior
                                                >
                                                    Sign Up
                                                    <i className="icon-d-right-arrow-2" />
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="counter-section">
                    <div className="container wow fadeInUp">
                        <div className="main-content">
                            <div className="row cus-mar">
                                <div className="col-lg-6 col-md-6">
                                    <div className="single-box d-flex align-items-center">
                                        <div className="img-area">
                                            <img
                                                src="assets/images/icon/counter-icon-1.png"
                                                alt="icon"
                                            />
                                        </div>
                                        <div className="text-area">
                                            <h6>Weekly Draw Prizes</h6>
                                            <h3>
                                                $<span className="counter">1350</span>
                                            </h3>
                                            <p>
                                                Next Draw in <span>04d 21h 46m</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="single-box second d-flex align-items-center">
                                        <div className="img-area">
                                            <img
                                                src="assets/images/icon/counter-icon-2.png"
                                                alt="icon"
                                            />
                                        </div>
                                        <div className="text-area">
                                            <h6>Redeemed Overall</h6>
                                            <h3>
                                                $<span className="counter">1144624</span>
                                            </h3>
                                            <p>
                                                Overall Redeems <span>claimed by players</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Banner Section end */}
            {/* Popular Game start */}
            <section className="popular-game">
                <div className="overlay pt-120 pb-120">
                    <div className="abs-item">
                        <img src="assets/images/popular-game-item.png" alt="icon" />
                    </div>
                    <div className="container">
                        <div className="row justify-content-center wow fadeInUp">
                            <div className="col-lg-6 text-center">
                                <div className="section-text">
                                    S<h5 className="sub-title">Play for FREE, win REAL prizes!</h5>
                                    <h2 className="title">600+ Leading Titles</h2>
                                    <p>
                                        At UltraSpin, it is always free to play. Promotional games void
                                        where prohibited.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row wow fadeInUp">
                            <div className="games-carousel">
                                <div className="single">
                                    <div className="single-box">
                                        <img
                                            src="assets/images/popular-game-image-11.png"
                                            alt="Image"
                                        />
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="single-box">
                                        <img
                                            src="assets/images/popular-game-image-22.png"
                                            alt="Image"
                                        />
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="single-box">
                                        <img
                                            src="assets/images/popular-game-image-33.png"
                                            alt="Image"
                                        />
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="single-box">
                                        <img
                                            src="assets/images/popular-game-image-44.png"
                                            alt="Image"
                                        />
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="single-box">
                                        <img
                                            src="assets/images/popular-game-image-55.png"
                                            alt="Image"
                                        />
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="single-box">
                                        <img
                                            src="assets/images/popular-game-image-66.png"
                                            alt="Image"
                                        />
                                    </div>
                                </div>
                                <div className="single">
                                    <div className="single-box">
                                        <img
                                            src="assets/images/popular-game-image-77.png"
                                            alt="Image"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="btn-area mt-40 text-center">
                                    <a href="/lobby" className="cmn-btn">
                                        All Games
                                        <i className="icon-d-right-arrow-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Popular Game end */}
            {/* Features start */}
            <section className="features">
                <div className="overlay pt-120 pb-120">
                    <div className="container wow fadeInUp">
                        <div className="row align-items-center">
                            <div className="col-lg-9">
                                <div className="section-top">
                                    <div className="section-text">
                                        <h5 className="sub-title">
                                            The Fastest Redeems In The Galaxy
                                        </h5>
                                        <h2 className="title">
                                            Enjoy fast, secure redeems at UltraSpin.
                                        </h2>
                                        <p>
                                            Secure, automated and lightning fast transactions with a
                                            wide selection of payment methods. Once verified, redeems are sent within minutes, not days or weeks!
                                        </p>
                                    </div>
                                    <div className="btn-area">
                                        <a href="/lobby" className="cmn-btn">
                                            Enter our Universe
                                            <i className="icon-d-right-arrow-2" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row cus-mar pt-120">
                            <div className="col-xl-3 col-sm-6">
                                <div className="single-box">
                                    <div className="icon-box">
                                        <img
                                            src="assets/images/icon/features-icon-1.png"
                                            alt="Image"
                                        />
                                    </div>
                                    <h3>50%</h3>
                                    <p>Welcome Bonus</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="single-box second">
                                    <div className="icon-box">
                                        <img
                                            src="assets/images/icon/features-icon-2.png"
                                            alt="Image"
                                        />
                                    </div>
                                    <h3>100%</h3>
                                    <p>Loyalty Rewards </p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="single-box third">
                                    <div className="icon-box">
                                        <img
                                            src="assets/images/icon/features-icon-3.png"
                                            alt="Image"
                                        />
                                    </div>
                                    <h3>600+</h3>
                                    <p>Hottest Games</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="single-box fourth">
                                    <div className="icon-box">
                                        <img
                                            src="assets/images/icon/features-icon-4.png"
                                            alt="Image"
                                        />
                                    </div>
                                    <h3>100%</h3>
                                    <p>Secure &amp; Stable</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Features end */}
            {/* How it works start */}
            <section className="how-works">
                <div className="overlay pb-120">
                    <div className="container wow fadeInUp">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="section-header text-center">
                                    <h5 className="sub-title">How it works</h5>
                                    <h2 className="title">It’s Really Easy!</h2>
                                    <p>It’s easier than you think. Follow 3 simple steps:</p>
                                </div>
                            </div>
                        </div>
                        <div className="row cus-mar">
                            <div className="col-xl-4 col-sm-6">
                                <div className="single-box text-center">
                                    <div className="thumb">
                                        <img
                                            src="assets/images/icon/how-works-icon-1.png"
                                            alt="icon"
                                        />
                                    </div>
                                    <div className="text-area">
                                        <h5>Quick Registration</h5>
                                        <p>Register and start playing within minutes. *No purchase necessary! </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="single-box mid text-center">
                                    <div className="thumb">
                                        <img
                                            src="assets/images/icon/how-works-icon-2.png"
                                            alt="icon"
                                        />
                                    </div>
                                    <div className="text-area">
                                        <h5>Fastest Top-up</h5>
                                        <p>Instant top-up with lightning fast crypto nodes built right in.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-sm-6">
                                <div className="single-box text-center">
                                    <div className="thumb">
                                        <img
                                            src="assets/images/icon/how-works-icon-3.png"
                                            alt="icon"
                                        />
                                    </div>
                                    <div className="text-area">
                                        <h5>Play and Win</h5>
                                        <p>Get the fastest redeems in the galaxy! *promotional games void where prohibited.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* How it works end */}
            {/* Drops & Wins start */}
            <section className="drops-wins">
                <div className="overlay pt-120 pb-120">
                    <div className="container wow fadeInUp">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-xxl-5 col-xl-6 col-md-6">
                                <div className="section-header">
                                    <h5 className="sub-title">Drops &amp; Wins </h5>
                                    <h2 className="title">Don't miss out!</h2>
                                </div>
                                <h2 className="currency">SS500.00</h2>
                                <div className="countdown d-flex align-items-center">
                                    <span>Finishes in:</span>
                                    <h6>
                                        <span className="days">00</span>
                                        <span className="ref">Days:</span>
                                    </h6>
                                    <h6>
                                        <span className="hours">00</span>
                                        <span className="ref">Hrs:</span>
                                    </h6>
                                    <h6>
                                        <span className="minutes">00</span>
                                        <span className="ref">Mins</span>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Drops & Wins end */}
            {/* Total Jackpot start */}
            <section className="total-jackpot">
                <div className="overlay pt-120 pb-120">
                    <div className="container wow fadeInUp">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="section-area text-center">
                                    <h5 className="sub-title">Latest Winners</h5>
                                    <h3 className="title">SC38,009</h3>
                                    <div className="btn-area mt-40">
                                        <a href="/lobby" className="cmn-btn">
                                            All Jackpot Games
                                            <i className="icon-d-right-arrow-2" />
                                        </a>
                                    </div>
                                    <div className="icon-img">
                                        <img src="assets/images/jackpot-icon.png" alt="icon" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="title mb-20 d-flex justify-content-between">
                                    <h4>Jackpot Games</h4>
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                        }}
                                    >
                                        Show All
                                    </a>
                                </div>
                                <div className="jackpot-carousel">
                                    <div className="single-slide">
                                        <div className="box">
                                            <div className="img-box">
                                                <img src="assets/images/jackpot-image-1.png" alt="icon" />
                                            </div>
                                            <div className="img-box">
                                                <img src="assets/images/jackpot-image-2.png" alt="icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-slide">
                                        <div className="box">
                                            <div className="img-box">
                                                <img src="assets/images/jackpot-image-3.png" alt="icon" />
                                            </div>
                                            <div className="img-box">
                                                <img src="assets/images/jackpot-image-1.png" alt="icon" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="single-slide">
                                        <div className="box">
                                            <div className="img-box">
                                                <img src="assets/images/jackpot-image-4.png" alt="icon" />
                                            </div>
                                            <div className="img-box">
                                                <img src="assets/images/jackpot-image-3.png" alt="icon" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Total Jackpot end */}
            {/* Refer start */}
            <section className="refer-section">
                <div className="overlay pt-120 pb-120">
                    <div className="container wow fadeInUp">
                        <div className="shape-area">
                            <img src="assets/images/refer-shape.png" alt="Image" />
                        </div>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6 order-lg-0 order-1">
                                <div className="bg-img d-rtl">
                                    <img
                                        src="assets/images/refer-illus.png"
                                        alt="Image"
                                        className="max-un"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="section-text">
                                    <h5 className="sub-title">Earn more with</h5>
                                    <h2 className="title">Refer and Earn</h2>
                                    <p>
                                        When you successfully join Ultra Partners, you will
                                        automatically receive an amazing 30% to 50% referral award for each new member that uses your link to join.
                                    </p>
                                </div>
                                <div className="btn-area">
                                    <a
                                        href="#"
                                        className="cmn-btn reg"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginMod"
                                        onClick={(e) => e.preventDefault()} // Prevent default anchor behavior
                                    >
                                        Join Now
                                        <i className="icon-d-right-arrow-2" />
                                    </a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Refer end */}
            {/* Footer Area Start */}
            <footer className="footer-section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="footer-form">
                                <div className="section-area mb-30 text-center">
                                    <h4 className="title">To Get Exclusive Benefits</h4>
                                </div>
                                <form action="#">
                                    <div className="form-group input-area d-flex align-items-center">
                                        <input type="text" placeholder="Enter your email address" />
                                        <button className="btn-area">
                                            <img src="assets/images/icon/send-icon.png" alt="icon" />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="footer-area pt-120">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="footer-top d-flex align-items-center justify-content-between footer-img">
                                    <a href="/home">
                                        <img
                                            src="assets/images/usmedium.png"
                                            className="logo"
                                            alt="logo"
                                        />
                                    </a>
                                    <div className="footer-box">
                                        <ul className="footer-link d-flex align-items-center gap-4">
                                            <li>
                                                <a href="/contact-us">Contact</a>
                                            </li>
                                            <li>
                                                <a href="/privacy-policy">Privacy Policy</a>
                                            </li>
                                            <li>
                                                <a href="/termandconditions">Terms of Service</a>
                                            </li>
                                            <li>
                                                <a href="/contact-us">KYC/AML</a>
                                            </li>
                                            <li>
                                                <a href="/privacy-policy">Sweepstakes Rules</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                                    <div className="copyright text-center">
                                        <p>© Tropic Gamers 2023 | All rights reserved.</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                                    <div className="social">
                                        <ul className="footer-link gap-2 d-flex align-items-center">
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent the default anchor behavior
                                                    }}
                                                >                                                <i className="fb fab fa-facebook-f" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent the default anchor behavior
                                                    }}
                                                >
                                                    <i className="ins fab fa-instagram" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent the default anchor behavior
                                                    }}
                                                >
                                                    <i className="tw fab fa-twitter" />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent the default anchor behavior
                                                    }}
                                                >
                                                    <i className="in fab fa-linkedin-in" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Area End */}
            {/*==================================================================*/}
        </>

    )
}
