import React, { useEffect, useRef } from 'react';
import spriteData from '../../images/frameanimation.json';
import spriteSheet from '../../images/frameanimation.png';

function drawSpriteFrame(ctx, frameIndex) {
    const frame = spriteData.frames[frameIndex].frame;
    const img = new Image();
    img.src = spriteSheet;
  
    img.onload = () => {
      ctx.clearRect(0, 0, frame.w, frame.h); 
      ctx.drawImage(  
        img,
        frame.x, frame.y, frame.w, frame.h, 
        0, 0, frame.w, frame.h 
      );
    };
  }

const SpriteAnimation = () => {
  const canvasRef = useRef(null);
  let frameIndex = 0;

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    const animateSprite = () => {
      drawSpriteFrame(ctx, frameIndex);
      frameIndex = (frameIndex + 1) % spriteData.frames.length; 
    };

    const interval = setInterval(animateSprite, 100); 

    return () => clearInterval(interval); 
  }, []);  

  return (
    <canvas
      ref={canvasRef}
      width={318} 
      height={218} 
      style={{ position: 'absolute', top: 0, left: 0, zIndex: 10 }}
    />
  );
};

export default SpriteAnimation;



