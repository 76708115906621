export const PrivacyPoliy = () => (
    <>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <link
        rel="shortcut icon"
        href="assets/images/fav.png"
        type="image/x-icon"
      />
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/jquery-ui.css" />
      <link rel="stylesheet" href="assets/css/plugin/nice-select.css" />
      <link rel="stylesheet" href="assets/css/plugin/magnific-popup.css" />
      <link rel="stylesheet" href="assets/css/plugin/slick.css" />
      <link rel="stylesheet" href="assets/css/ara-font.css" />
      <link rel="stylesheet" href="assets/css/plugin/animate.css" />
      <link rel="stylesheet" href="assets/css/style.css" />
      {/* Scroll To Top Start*/}
      <a href="javascript:void(0)" className="scrollToTop">
        <i className="fas fa-angle-double-up" />
      </a>
      {/* Scroll To Top End */}
      {/* header-section start */}
      <header className="header-section">
            <div className="overlay">
                <div className="container">
                    <div className="row d-flex header-area">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <a className="navbar-brand" href="/home">
                                <img
                                    src="assets/images/fav.png"
                                    className="fav d-none d-lg-block d-xl-none"
                                    alt="fav"
                                />
                                <img
                                    src="assets/images/logo.png"
                                    className="logo d-block d-lg-none d-xl-block"
                                    alt="logo"
                                />
                            </a>
                            <button
                                className="navbar-toggler collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbar-content"
                            >
                                <i className="fas fa-bars" />
                            </button>
                            <div
                                className="collapse navbar-collapse justify-content-between"
                                id="navbar-content"
                            >
                                <div className="right-area header-action d-flex align-items-center max-un">
                                    <button
                                        type="button"
                                        className="login"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginMod"
                                    >
                                        Sign In
                                    </button>
                                    <button
                                        type="button"
                                        className="cmn-btn reg"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginMod"
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        {/* header-section end */}
      {/* Login Registration start */}
      <div className="log-reg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="modal fade" id="loginMod">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header justify-content-center">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <ul className="nav log-reg-btn d-none justify-content-around">
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link"
                          id="regArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#regArea"
                          type="button"
                          role="tab"
                          aria-controls="regArea"
                          aria-selected="false"
                        >
                          SIGN UP
                        </button>
                      </li>
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link active"
                          id="loginArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#loginArea"
                          type="button"
                          role="tab"
                          aria-controls="loginArea"
                          aria-selected="true"
                        >
                          LOGIN
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="loginArea"
                        role="tabpanel"
                        aria-labelledby="loginArea-tab"
                      >
                        <div className="login-reg-content">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h3 className="title">Welcome Back</h3>
                                  <p>
                                    Not a member ?{" "}
                                    <a
                                      href="javascript:void(0)"
                                      className="reg-btn"
                                    >
                                      Register
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="checkbox-item d-flex justify-content-between">
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>Remember me</span>
                                      </span>
                                    </span>
                                  </label>
                                  <a href="javascript:void(0)">Forget Password</a>
                                </div>
                                <div className="btn-area text-center">
                                  <a
                                    href="javascript:void(0)"
                                    className="cmn-btn mt-4"
                                  >
                                    Login
                                    <i className="icon-d-right-arrow-2" />
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="regArea"
                        role="tabpanel"
                        aria-labelledby="regArea-tab"
                      >
                        <div className="login-reg-content regMode">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h5 className="sub-title">
                                    Ready to get started?
                                  </h5>
                                  <h3 className="title">
                                    Welcome Bonus +50% SC!
                                  </h3>
                                  <p>
                                    Already have an account?{" "}
                                    <a
                                      href="javascript:void(0)"
                                      className="log-btn"
                                    >
                                      Login
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="First Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Last Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Phone Number" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input
                                    type="date"
                                    placeholder="Date of Birth"
                                  />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="sidebar-single-item">
                                  <label className="checkbox-single d-flex">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input
                                          type="checkbox"
                                          defaultChecked="checked"
                                        />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I am at least 18 years of age and I have
                                          read, accepted and agreed to the{" "}
                                          <u>
                                            <a href="javascript:void(0)">
                                              Privacy Policy
                                            </a>
                                            , Bonus T&amp;C,{" "}
                                            <a href="javascript:void(0)">
                                              Terms and Conditions
                                            </a>
                                          </u>
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I want to receive promotions by e-mail.
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <div className="btn-area text-center">
                                    <a
                                      href="javascript:void(0)"
                                      className="cmn-btn mt-4"
                                    >
                                      Get Started Now
                                      <i className="icon-d-right-arrow-2" />
                                    </a>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Login Registration end */}
      {/* Banner Section start */}
      <section className="banner-section inner-banner contact">
        <div className="overlay">
          <div className="banner-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-10">
                  <div className="main-content">
                    <h2>Privacy Policy</h2>
                    <div className="breadcrumb-area">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb d-flex align-items-center">
                          <li className="breadcrumb-item">
                            <a href="index.html">Home</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="javascript:void(0)">Pages</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Privacy Policy
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Section end */}
      {/* Privacy Content In start */}
      <section className="privacy-content">
        <div className="overlay pt-120 pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="top-wrapper">
                  <h4>
                    We're always looking for new ways to provide privacy for our
                    customers.
                  </h4>
                  <p>
                    At Tropic Gamers LLC, we prioritize your privacy. This Privacy
                    Policy outlines how we handle personal information collected
                    through our platforms, including Tropix.com and our online
                    storefront at shop.tropix.com, as well as any other services
                    that link to this Privacy Policy.
                  </p>
                  <p>Last Updated: August 1st, 2024</p>
                  <p>
                    This Privacy Policy describes how Tropic Gamers LLC handles
                    personal information that we collect through our metaverse
                    platform available at Tropix.com, our online storefront
                    available at shop.tropix.com, website, and any other sites or
                    services that link to this Privacy Policy (collectively, the
                    “Platform”).
                  </p>
                  <h5>Personal Information We Collect</h5>
                  <p>
                    When you interact with us through the Platform, we may collect
                    personal information and other information from you, as
                    further described below:
                  </p>
                  <h6>Information you provide</h6>
                  <ul>
                    <li>
                      Registration of account information, including your name,
                      username, password, email address, and other information you
                      provide to us when creating an account.
                    </li>
                    <li>
                      Purchase and transaction information, for example when you
                      purchase a product or service from us, we will collect
                      certain information which may include identification
                      information, your date of birth, your postal and/or
                      residential address, telephone number, and payment
                      information. Payment information is processed by our
                      third-party payment processor Shopify Payments, in
                      accordance with Shopify Payments’ privacy policy and terms
                      of service. We do not have access to payment card numbers.
                    </li>
                    <li>
                      Information you provide when using our services, such as the
                      information you provide to us when you interact with our
                      Platform, including information about the products you use
                      or purchase on our Platform (such as the product
                      information, date of purchase, and information relating to
                      any support issue). Please note that our services as it
                      relates to our metaverse platform may offer chat functions
                      (either one-to-one or within a limited group) or other forms
                      of communication services (such as real-time voice
                      communication either one-to-one or within a limited group),
                      forums, community environments (including multiplayer
                      gameplay), or other tools or services that may be accessible
                      by anyone. Any information, content, or communications,
                      including personal information, you provide when you use any
                      of these features will be available to the recipients and
                      may be publicly posted and otherwise disclosed without
                      limitation as to its use by us or by a third party. While we
                      endeavor at all times to protect the privacy of our users
                      and to ensure no harm is caused through our services, we
                      have no obligation to keep private any information you made
                      available to other users or the public using these
                      functions.
                    </li>
                    <li>
                      Surveys, Contests, and Promotions that we sometimes offer
                      you to take part in. Depending on the nature of the survey,
                      contest, or promotion, we may ask you, or you may volunteer,
                      to provide certain personal information, such as your name,
                      address, or opinion.
                    </li>
                    <li>
                      Communications that we exchange with you, including when you
                      contact us with questions, feedback, or otherwise.
                    </li>
                    <li>
                      Marketing information, such as your preferences for
                      receiving communications about our services and
                      publications, and details about how you engage with our
                      communications.
                    </li>
                  </ul>
                  <h6>Information we obtain from other sources</h6>
                  <ul>
                    <li>
                      Social media information. We may maintain pages on social
                      media platforms, such as Facebook, YouTube, and Instagram.
                      When you visit or interact with our pages on those
                      platforms, you or the platforms may provide us with
                      information through the platform.
                    </li>
                    <li>
                      Third-party logins. When you link, connect, or login to the
                      services with a third-party service, you direct the service
                      to send us information as controlled by that service or as
                      authorized by you via your privacy settings at that service.
                    </li>
                  </ul>
                  <h6>Automatic data collection</h6>
                  <p>
                    We and our service providers may automatically log information
                    about you, your computer or mobile device, and your
                    interactions over time with our Platform, our communications,
                    and other online services, such as:
                  </p>
                  <ul>
                    <li>
                      Device data, such as your computer’s or mobile device’s
                      operating system type and version, manufacturer and model,
                      browser type, screen resolution, RAM and disk size, CPU
                      usage, device type (e.g., phone, tablet), IP address, unique
                      identifiers, language settings, mobile device carrier,
                      radio/network information (e.g., WiFi, LTE, 4G), and general
                      location information such as city, state, or geographic
                      area.
                    </li>
                    <li>
                      Online activity data, such as pages or screens you viewed,
                      how long you spent on a page or screen, browsing history,
                      navigation paths between pages or screens, information about
                      your activity on a page or screen, access times, duration of
                      access, and whether you have opened our marketing emails or
                      clicked links within them.
                    </li>
                  </ul>
                  <h5>How We Use Personal Information</h5>
                  <p>
                    We may use your personal information for the following
                    purposes:
                  </p>
                  <ul>
                    <li>
                      To provide our services, including enabling you to create an
                      account, processing your payments, administering, hosting,
                      and operating our services and Platform, administering
                      surveys, contests, or promotions, and communicating with you
                      and responding to any inquiries you may have.
                    </li>
                    <li>
                      For research and development, including analyzing and
                      improving our Platform and business, creating aggregated,
                      de-identified, or other anonymous data from personal
                      information we collect, and using this anonymous data for
                      our lawful business purposes.
                    </li>
                    <li>
                      For marketing and advertising, including sending direct
                      marketing communications, engaging in interest-based
                      advertising, and conducting marketing based on our
                      legitimate business interests.
                    </li>
                    <li>
                      For compliance and protection, including complying with
                      applicable laws, protecting our rights, auditing our
                      internal processes, enforcing the terms governing our
                      Platform, and preventing unauthorized or illegal activities.
                    </li>
                  </ul>
                  <h6>
                    Legal bases for processing (for United Kingdom and EEA
                    individuals)
                  </h6>
                  <p>
                    If you are an individual in the United Kingdom or European
                    Economic Area (EEA), we collect and process information about
                    you only where we have legal bases for doing so under
                    applicable United Kingdom and/or EU laws.
                  </p>
                  <h5>How We Share Personal Information</h5>
                  <p>
                    We may share your personal information with service providers,
                    professional advisors, authorities, and others as necessary to
                    operate our business and comply with legal obligations.
                  </p>
                  <h6>Cross-border processing of your personal information</h6>
                  <p>
                    Tropic Gamers LLC is headquartered in the United States. To
                    provide and operate our services, it is necessary for us to
                    process your personal information in the United States.
                  </p>
                  <h6>Privacy Rights and Choices</h6>
                  <p>
                    You have certain rights in relation to your personal
                    information, including the right to access, correct, or delete
                    your personal information, and the right to opt out of certain
                    data processing activities.
                  </p>
                  <h6>Online tracking opt-out</h6>
                  <p>
                    You can opt out of online tracking through various mechanisms
                    such as blocking cookies in your browser, using privacy
                    plug-ins or browsers, and opting out of Google Analytics.
                  </p>
                  <h5>Security practices</h5>
                  <p>
                    We use reasonable organizational, technical, and
                    administrative measures designed to protect against
                    unauthorized access, misuse, loss, disclosure, alteration, and
                    destruction of personal information we maintain. However, data
                    transmission over the internet cannot be guaranteed as
                    completely secure.
                  </p>
                  <h6>Data Retention</h6>
                  <p>
                    We may retain your personal information for as long as
                    necessary to maintain and expand our relationship, provide
                    services, comply with legal obligations, and protect ourselves
                    from potential disputes.
                  </p>
                  <h6>Children</h6>
                  <p>
                    Our Platform is not intended for children under 18 years old.
                    If we learn that we have collected personal information from a
                    child without verification of parental consent, we will delete
                    the information.
                  </p>
                  <h5>Changes to This Privacy Policy</h5>
                  <p>
                    We reserve the right to modify this Privacy Policy at any
                    time. If we make material changes, we will notify you by
                    updating the date of this Privacy Policy or via email if
                    applicable.
                  </p>
                  <h5>How to Contact Us</h5>
                  <p>
                    If you have any questions about our Privacy Policy or
                    information practices, please contact us at:{" "}
                    <a href="mailto:info@tropix.vip">info@tropix.vip</a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Create Account In end */}
      {/* Footer Area Start */}
      <footer className="footer-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="footer-form">
                <div className="section-area mb-30 text-center">
                  <h4 className="title">To Get Exclusive Benefits</h4>
                </div>
                <form action="#">
                  <div className="form-group input-area d-flex align-items-center">
                    <input type="text" placeholder="Enter your email address" />
                    <button className="btn-area">
                      <img src="assets/images/icon/send-icon.png" alt="icon" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="footer-area pt-120">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-top d-flex align-items-center justify-content-between">
                  <a href="/home">
                    <img
                      src="assets/images/logo.png"
                      className="logo"
                      alt="logo"
                    />
                  </a>
                  <div className="footer-box">
                    <ul className="footer-link d-flex align-items-center gap-4">
                    <li>
                        <a href="/contact-us">Contact</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/termandconditions">Terms of Service</a>
                      </li>
                      <li>
                        <a href="/contact-us">KYC/AML</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Sweepstakes Rules</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                  <div className="copyright text-center">
                    <p>© Tropic Gamers 2023 | All rights reserved.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                  <div className="social">
                    <ul className="footer-link gap-2 d-flex align-items-center">
                      <li>
                        <a href="javascript:void(0)">
                          <i className="fb fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="ins fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="tw fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="in fab fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Area End */}
      {/*==================================================================*/}
    </>
  )
  