import React, { useState, useRef, useEffect } from 'react';
import './SearchModal.css';  // Add CSS for styling the modal

const SearchModal = ({ isOpen, onClose, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const modalRef = useRef();

  // Close the modal when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen, onClose]);

  const handleSearch = () => {
    onSearch(searchTerm);  // Trigger the search function passed from the parent
    onClose();  // Close the modal after searching
  };

  return (
    isOpen && (
      <div className="search-modal-overlay">
        <div className="search-modal" ref={modalRef}>
          <div className="search-modal-header">
            <h2>Search Games</h2>
            <button onClick={onClose} className="close-button" aria-label="Close modal">X</button>
          </div>
          <div className="search-modal-body">
            <input
              type="text"
              placeholder="Search for a game..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
              aria-label="Search input"
            />
          </div>
          <div className="search-modal-footer">
            <button onClick={handleSearch} className="search-button" aria-label="Search for games">Search</button>
          </div>
        </div>
      </div>
    )
  );
};

export default SearchModal;
