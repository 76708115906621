import React, { useEffect, useState, useRef } from 'react';
import Topbar from '../components/Lobby/TopBar';
import Background from '../components/Lobby/Background';
import Lowerbar from '../components/Lobby/LowerBar';
import GameGrid from '../components/Lobby/GameGrid';
import PolicyModal from '../components/Modals/PolicyModal';
import SearchModal from '../components/Modals/SearchModal';  // Import the search modal
import lobbyMusic from '../images/lobbymusic.mp3';

const Lobby = () => {
  const [isPolicyModalOpen, setIsPolicyModalOpen] = useState(true);
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);  // Add search modal state
  const [volume, setVolume] = useState(0.5);
  const [games, setGames] = useState([]);  // Store all games
  const [filteredGames, setFilteredGames] = useState([]); // Store filtered games
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
      audioRef.current.play().catch((error) => {
        console.error('Audio play failed: ', error);
      });
    }
  }, []);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = volume;
    }
  }, [volume]);

  // Fetch games once when component mounts
  useEffect(() => {
    const fetchGames = async () => {
      try {
        const response = await fetch('https://api.ultraspin.vip/api/games');
        const data = await response.json();
        setGames(data.items);
        setFilteredGames(data.items); // Default to show all games initially
      } catch (error) {
        console.error('Error fetching games:', error);
      }
    };

    fetchGames();
  }, []);

  // Function to handle filter selection
  const handleFilter = (filterType) => {
    switch (filterType) {
      case 'ALL':
        setFilteredGames(games);
        break;
      case 'SLOTS':
        setFilteredGames(games.filter(game => game.type === 'slots'));
        break;
      case 'FISHING_ARCADE':
        setFilteredGames(games.filter(game => game.type === 'fishing' || game.type === 'arcade'));
        break;
      case 'TABLE_BINGO_POKER':
        setFilteredGames(games.filter(game => ['table', 'bingo', 'poker'].includes(game.type)));
        break;
      case 'FAVORITES':
        const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
        setFilteredGames(games.filter(game => favorites.includes(game.uuid)));
        break;
      default:
        break;
    }
  };

  // Handle search functionality
  const handleSearch = (searchTerm) => {
    setFilteredGames(games.filter(game => game.name.toLowerCase().includes(searchTerm.toLowerCase())));
  };

  // Open search modal when Icon6 is clicked
  const handleSearchIconClick = () => {
    setIsSearchModalOpen(true);
  };

  const updateAspectRatio = () => {
    const container = document.getElementById('lobby-content');
    if (!container) return;

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const aspectRatio = windowWidth / windowHeight;

    if (windowWidth < 768) {
      if (aspectRatio < 16 / 9) {
        container.style.width = `${windowHeight * (16 / 9)}px`;
        container.style.height = 'auto';
      } else if (aspectRatio > 20 / 9) {
        container.style.width = `${windowHeight * (20 / 9)}px`;
        container.style.height = 'auto';
      } else {
        container.style.width = `${windowWidth}px`;
        container.style.height = 'auto';
      }
      container.style.aspectRatio = '16 / 9';
    } else if (windowWidth <= 1196) {
      if (aspectRatio < 4 / 3) {
        container.style.width = `${windowHeight * (4 / 3)}px`;
        container.style.height = 'auto';
      } else if (aspectRatio > 16 / 9) {
        container.style.width = `${windowHeight * (16 / 9)}px`;
        container.style.height = 'auto';
      } else {
        container.style.width = `${windowWidth}px`;
        container.style.height = 'auto';
      }
      container.style.aspectRatio = '16 / 9';
    }
    else {
      if (aspectRatio < 16 / 9) {
        container.style.width = `${windowHeight * (16 / 9)}px`;
        container.style.height = `${windowHeight}px`;
      } else if (aspectRatio > 20 / 9) {
        container.style.width = `${windowHeight * (20 / 9)}px`;
        container.style.height = `${windowHeight}px`;
      } else {
        container.style.width = `${windowWidth}px`;
        container.style.height = `${windowWidth / aspectRatio}px`;
      }
    }
    container.style.maxWidth = '2500px';
    container.style.maxHeight = '1406px';
  };

  useEffect(() => {
    updateAspectRatio();
    window.addEventListener('resize', updateAspectRatio);

    return () => {
      window.removeEventListener('resize', updateAspectRatio);
    };
  }, []);

  return (
    <div style={styles.wrapper}>
      <div style={styles.lobbyContainer} id="lobby-content">
        <Background />
        <Topbar />
        <GameGrid games={filteredGames} />  {/* Pass the filtered games */}
        <Lowerbar onFilter={handleFilter} onSearchIconClick={handleSearchIconClick} /> {/* Pass the filter and search functions */}
        <audio ref={audioRef} src={lobbyMusic} loop />
        <PolicyModal
          isOpen={isPolicyModalOpen}
          onClose={() => setIsPolicyModalOpen(false)}
        />
        <SearchModal
          isOpen={isSearchModalOpen}
          onClose={() => setIsSearchModalOpen(false)}
          onSearch={handleSearch}
        />
      </div>
    </div>
  );
};


const styles = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100vw',
    height: '100vh',
    backgroundColor: 'black',
    overflow: 'hidden',
  },
  lobbyContainer: {
    position: 'relative',
    backgroundColor: 'black',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '2500px',
    maxHeight: '1406px',
  },
};

export default Lobby;
