import React from 'react';
import lowerbarImage from '../../images/lowerbar.png';
import PrizeWheel from './PrizeWheel';
import icon1 from '../../images/all.png'; 
import icon2 from '../../images/jackpot.png'; 
import icon3 from '../../images/fish.png'; 
import icon4 from '../../images/fav.png'; 
import icon5 from '../../images/table.png'; 
import icon6 from '../../images/other.png';  

const Lowerbar = ({ onFilter, onSearchIconClick }) => {
  // Function to filter favorites from localStorage
  const handleFavoritesFilter = () => {
    const favoriteGames = JSON.parse(localStorage.getItem('favorites')) || [];
    onFilter('FAVORITES', favoriteGames); // Pass favorite game UUIDs to filter
  };

  return (
    <div style={styles.container}>
      <PrizeWheel />
      <img src={lowerbarImage} alt="Lowerbar" style={styles.lowerbar} />
      <div style={styles.iconsContainer}>
        <div style={styles.leftIcons}>
          <button onClick={() => onFilter('ALL')} style={styles.iconButton}>
            <img src={icon1} alt="All Games" style={styles.icon} />
          </button>
          <button onClick={() => onFilter('SLOTS')} style={styles.iconButton}>
            <img src={icon2} alt="Slots" style={styles.icon} />
          </button>
          <button onClick={() => onFilter('FISHING_ARCADE')} style={styles.iconButton}>
            <img src={icon3} alt="Fishing and Arcade" style={styles.icon} />
          </button>
        </div>
        <div style={styles.timeDisplay}>12:00</div>
        <div style={styles.rightIcons}>
          <button onClick={handleFavoritesFilter} style={styles.iconButton}>  {/* Favorites filter */}
            <img src={icon4} alt="Favorites" style={styles.icon} />
          </button>
          <button onClick={() => onFilter('TABLE_BINGO_POKER')} style={styles.iconButton}>
            <img src={icon5} alt="Table Games" style={styles.icon} />
          </button>
          <button onClick={onSearchIconClick} style={styles.iconButton}>  {/* Open search modal */}
            <img src={icon6} alt="Search" style={styles.icon} />
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lowerbar: {
    width: '100%',
    height: 'auto',
    zIndex: 20, // lowerbar stays above the prize wheel
  },
  iconsContainer: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    marginTop: '5%',
    justifyContent: 'space-around',
    zIndex: 30, // icons and time stay above the lowerbar image
  },
  leftIcons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap:'3vw'

  },
  rightIcons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap:'3vw'

  },
  iconButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    margin: '0 10px', // Reduced margin for smaller gaps
  },
  icon: {
    width: '4vw', 
    height: '4vw', 
  },
  timeDisplay: {
    fontSize: '1.75vw',
    paddingTop: '0.5vw',
    color: '#FFFFFF', 
    zIndex: 40,
  },
};

export default Lowerbar;
