import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Lobby from './pages/Lobby'; 
import { HomePage } from './components/HomePage';
import { ContactUs } from './components/ContactUs';
import { TermCondition } from './components/TermCondition';
import { AuthProvider } from './AuthContext'; 
import ProtectedRoute from './ProtectedRoute'; 
import { PrivacyPoliy } from './components/PrivacyPolicy';
import Sudo from './components/Sudo';  

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPoliy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/termandconditions" element={<TermCondition />} />
          <Route path="/Sudo" element={<Sudo />} /> 

          {/* Switch between protected and non-protected route for Lobby */}
          
          {/* Non-protected route (use for development) */}
          
          <Route path="/lobby" element={<Lobby />} /> 
         
          
          {/* Protected route (use for production) */}
          {/* <Route
            path="/lobby"
            element={
              <ProtectedRoute>
                <Lobby />
              </ProtectedRoute>
            }
          /> */}
          
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;

