import React from 'react';
import prizewheelImage from '../../images/prizewheel.png';
import './PrizeWheel.css';

const PrizeWheel = () => {
  return (
    <div className="prizewheel-container" style={styles.container}>
      <img src={prizewheelImage} alt="Prize Wheel" className="prizewheel" />
      {/* Applying the prizewheel class */}
    </div>
  );
};

const styles = {
  container: {
    position: 'absolute',
    bottom: '-5%', // Align within the lowerbar window, adjust as needed
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 3,
  },
};

export default PrizeWheel;
