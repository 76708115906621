import React, { useState, useEffect } from 'react';
import topbarImage from '../../images/topbar.png';
import Settings from '../Modals/Settings'; // Import Settings modal
import PolicyModal from '../Modals/PolicyModal'; // Import PolicyModal
import lobbyMusic from '../../images/lobbymusic.mp3';

const Topbar = () => {
  const [goldCoins, setGoldCoins] = useState(0);
  const [sweepsStars, setSweepsStars] = useState(0);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false); // State for settings modal
  const [isPolicyOpen, setIsPolicyOpen] = useState(false); // State for policy modal
  const [volume, setVolume] = useState(0.5); // Default volume
  const [audio] = useState(new Audio(lobbyMusic)); // Load the audio
  const [isMuted, setIsMuted] = useState(false); // State for mute

  // Fetch user balance
  useEffect(() => {
    const fetchBalance = async () => {
      console.log("Fetching balance...");

      try {
        const sessionToken = localStorage.getItem('authToken');
        if (sessionToken) {
          const response = await fetch('https://api.ultraspin.vip/api/users/balance', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${sessionToken}`,
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const data = await response.json();
            setGoldCoins(data.gold_coins);
            setSweepsStars(data.sweeps_stars);
          } else {
            console.error('Failed to retrieve balance');
          }
        } else {
          console.error('No session token found');
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };

    fetchBalance();
  }, []);

  // Play music when component mounts
  useEffect(() => {
    const handlePlay = () => {
      audio.volume = volume; // Set initial volume
      audio.play().catch((error) => console.log('Playback prevented:', error)); 
    };

    // Ensure the audio loops when it ends
    audio.loop = true;

    window.addEventListener('click', handlePlay, { once: true }); // Play audio when user interacts

    return () => {
      window.removeEventListener('click', handlePlay);
    };
  }, [audio, volume]);

  // Toggle mute
  const handleToggleMute = () => {
    setIsMuted(!isMuted);
    audio.muted = !audio.muted;
  };

  // Update volume in real-time
  const handleVolumeChange = (newVolume) => {
    setVolume(newVolume);
    audio.volume = newVolume; // Update volume live
  };

  // Handle fullscreen toggle
  const handleToggleFullscreen = async () => {
    const elem = document.documentElement;
    if (document.fullscreenElement) {
      await document.exitFullscreen();
    } else {
      if (elem.requestFullscreen) {
        await elem.requestFullscreen();
      }
    }
  };

  return (
    <div style={styles.container}>
      <img src={topbarImage} alt="Topbar" style={styles.image} />
      <div style={styles.leftText}>{goldCoins} GC</div>
      <div style={styles.rightText}>{sweepsStars} SS</div>

      {/* Clickable Areas */}
      <div style={styles.leftClickableArea} onClick={() => alert('Home clicked!')} />
      <div style={styles.rightClickableArea} onClick={() => setIsSettingsOpen(true)} />
      <div style={styles.centerClickableArea} onClick={() => alert('Coins clicked!')} />
      <div style={styles.bottomClickableArea} onClick={() => setIsPolicyOpen(true)} /> {/* Sweeper opens policy */}

      {/* Settings Modal */}
      <Settings 
        isOpen={isSettingsOpen} 
        onClose={() => setIsSettingsOpen(false)} 
        volume={volume} 
        onVolumeChange={handleVolumeChange} 
        onToggleMute={handleToggleMute}
        isMuted={isMuted}
        onToggleFullscreen={handleToggleFullscreen}
      />

      {/* Policy Modal */}
      <PolicyModal 
        isOpen={isPolicyOpen} 
        onClose={() => setIsPolicyOpen(false)} 
      />
    </div>
  );
};

const styles = {
  container: {
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 3,
    textAlign: 'center',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  leftText: {
    position: 'absolute',
    left: '16vw',
    top: '24%', 
    color: 'white',
    fontSize: '2vw',
    fontFamily: 'Roboto, sans-serif',  
    fontWeight: 700, 
  },
  rightText: {
    position: 'absolute',
    right: '17.5vw',
    top: '24%', 
    color: 'white',
    fontSize: '2vw',
    fontFamily: 'Roboto, sans-serif',  
    fontWeight: 700, 
  },
  leftClickableArea: {
    position: 'absolute',
    left: '1%',
    top: '10%',
    width: '5%', 
    height: '70%', 
    cursor: 'pointer',
  },
  rightClickableArea: {
    position: 'absolute',
    right: '1%',
    top: '10%',
    width: '5%', 
    height: '75%', 
    cursor: 'pointer',
  },
  centerClickableArea: {
    position: 'absolute',
    left: '29%',
    top: '50%',
    width: '2%', 
    height: '50%', 
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
  },
  bottomClickableArea: {
    position: 'absolute',
    right: '6%',
    top: '15%',
    width: '5%', 
    height: '50%', 
    transform: 'translate(-50%, 0)',
    cursor: 'pointer',
  },
};

export default Topbar;