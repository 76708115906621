import React, { useEffect, useRef } from 'react';
import sudoData from '../../images/sudo.json'; 
import sudoSprite from '../../images/sudo.png'; 
import './SudoAnimation.css'; // Import the CSS file

// Function to draw each frame on the canvas
function drawSudoFrame(ctx, frameIndex) {
  const frame = sudoData.frames[frameIndex].frame; // Extract frame from JSON
  const img = new Image();
  img.src = sudoSprite; // Load sprite sheet

  img.onload = () => {
    // Clear previous frame
    ctx.clearRect(0, 0, frame.w, frame.h);

    // Draw the new frame on the canvas
    ctx.drawImage(
      img,
      frame.x, frame.y, frame.w, frame.h, // Source frame coordinates
      0, 0, frame.w, frame.h // Destination on canvas
    );
  };
}

const SudoAnimation = () => {
  const canvasRef = useRef(null);
  let frameIndex = 0;

  useEffect(() => {
    const ctx = canvasRef.current.getContext('2d');

    // Function to update the sprite frame
    const animateSudo = () => {
      drawSudoFrame(ctx, frameIndex);
      frameIndex = (frameIndex + 1) % sudoData.frames.length; // Loop through frames
    };

    const interval = setInterval(animateSudo, 100); // Adjust animation speed (100ms)

    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);  

  return (
    <div className="sudo-animation-container">
      <canvas
        ref={canvasRef}
        width={325}  // Set canvas width to 325px
        height={625} // Set canvas height to 625px
        className="sudo-animation-canvas"
      />
    </div>
  );
};

export default SudoAnimation;