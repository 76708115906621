import React, { useState, useEffect, useRef } from 'react';
import './GameGrid.css';
import SpriteAnimation from './SpriteAnimation';
import HotAnimation from './HotAnimation';
import SudoAnimation from './../Sudo/SudoAnimation';
import UserImage from '../../images/avatar.png';
import RobotStandImage from '../../images/stand.png';
import heartFaded from '../../images/heart-faded.png';
import heartColored from '../../images/heart-colored.png';

const GameGrid = ({ games }) => {
  const [favorites, setFavorites] = useState(Array(50).fill(false)); // Assuming 50 games max; adjust as needed
  const [user, setUser] = useState(null);
  const gridRef = useRef(null);
  let isDragging = false;
  let startX;
  let scrollLeft;

  // Fetch user details on mount (assuming you need this logic)
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const sessionToken = localStorage.getItem('authToken');
        if (sessionToken) {
          const response = await fetch('https://api.ultraspin.vip/api/users/me', {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${sessionToken}`,
              'Content-Type': 'application/json',
            },
          });

          if (response.ok) {
            const data = await response.json();
            setUser(data);
          } else {
            console.error('Failed to retrieve user details');
          }
        } else {
          console.error('No session token found');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      }
    };

    fetchUser();
  }, []);

  // Function to initialize game session when a game is clicked
  const initGameSession = async (gameUuid) => {
    try {
      if (!user) {
        throw new Error("User details not available. Cannot initialize game session.");
      }

      const sessionToken = localStorage.getItem('authToken');
      if (sessionToken) {
        const response = await fetch('https://api.ultraspin.vip/api/games/init', {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${sessionToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            game_uuid: gameUuid,
            player_id: user.id,
            player_name: user.username,
            currency: 'USD',
          }),
        });

        if (!response.ok) throw new Error(`Error initializing game: ${response.status}`);
        const data = await response.json();
        window.location.href = data.url; // Redirect to game URL
      } else {
        console.error('No session token found. Cannot initialize game session.');
      }
    } catch (error) {
      console.error('Error initializing game:', error.message);
    }
  };

  // Handle toggling of favorites
  const toggleFavorite = (index) => {
    const newFavorites = [...favorites];
    newFavorites[index] = !newFavorites[index];
    setFavorites(newFavorites);
    const favoriteGames = JSON.parse(localStorage.getItem('favorites')) || [];
    if (newFavorites[index]) {
      favoriteGames.push(games[index].uuid); // Add to favorites
    } else {
      const favIndex = favoriteGames.indexOf(games[index].uuid);
      if (favIndex > -1) {
        favoriteGames.splice(favIndex, 1); // Remove from favorites
      }
    }
    localStorage.setItem('favorites', JSON.stringify(favoriteGames));
  };

  // Drag-and-Scroll behavior
  const handleMouseDown = (e) => {
    isDragging = true;
    startX = (e.pageX || e.touches[0].pageX) - gridRef.current.offsetLeft;
    scrollLeft = gridRef.current.scrollLeft;
    gridRef.current.classList.add('active');
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = (e.pageX || e.touches[0].pageX) - gridRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Speed of the drag scroll
    gridRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    isDragging = false;
    gridRef.current.classList.remove('active');
  };

  const handleTouchStart = (e) => handleMouseDown(e);
  const handleTouchMove = (e) => handleMouseMove(e);
  const handleTouchEnd = () => handleMouseUp();

  return (
    <div className="main-container">
      <div className="user-info">
        <img src={UserImage} alt="User Avatar" className="user-avatar" />
        <div className='user-text'>{user ? user.username : 'Loading...'}</div>
      </div>
      <div className='animation-container'>
        <SudoAnimation />
      </div>
      <div className="robot-info">
        <img src={RobotStandImage} alt="Robot Avatar" className="robot-avatar" />
      </div>
      <div
        className="grid-container"
        ref={gridRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {games.length > 0 ? (
          games.map((game, index) => (
            <div
              key={index}
              className="grid-item"
              onClick={() => initGameSession(game.uuid)} // Start game session
              style={{ position: 'relative' }}
            >
              <div className="image-wrapper">
                <img
                  src={game.image || 'https://via.placeholder.com/250'}
                  alt={game.name || 'Game'}
                  className="grid-image"
                />
              </div>
              <SpriteAnimation startFrame={index % 10} />

              {/* HOT Animation every 10th game */}
              {index % 10 === 0 && <HotAnimation />}

              {/* Heart Icon for Favoriting */}
              <div
                className={`heart-icon ${favorites[index] ? 'favorite' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();  // Prevent triggering game click
                  toggleFavorite(index);
                }}
              >
                <img
                  src={favorites[index] ? heartColored : heartFaded}
                  alt="Favorite"
                  className="heart-icon-image"
                />
              </div>
            </div>
          ))
        ) : (
          <p>No games available</p>
        )}
      </div>
    </div>
  );
};

export default GameGrid;