export const TermCondition = () => (
    <>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      <link
        rel="shortcut icon"
        href="assets/images/fav.png"
        type="image/x-icon"
      />
      <link rel="stylesheet" href="assets/css/bootstrap.min.css" />
      <link rel="stylesheet" href="assets/css/fontawesome.min.css" />
      <link rel="stylesheet" href="assets/css/jquery-ui.css" />
      <link rel="stylesheet" href="assets/css/plugin/nice-select.css" />
      <link rel="stylesheet" href="assets/css/plugin/magnific-popup.css" />
      <link rel="stylesheet" href="assets/css/plugin/slick.css" />
      <link rel="stylesheet" href="assets/css/ara-font.css" />
      <link rel="stylesheet" href="assets/css/plugin/animate.css" />
      <link rel="stylesheet" href="assets/css/style.css" />
      {/* start preloader */}
      <div className="preloader" id="preloader" />
      {/* end preloader */}
      {/* Scroll To Top Start*/}
      <a href="javascript:void(0)" className="scrollToTop">
        <i className="fas fa-angle-double-up" />
      </a>
      {/* Scroll To Top End */}
   {/* header-section start */}
   <header className="header-section">
            <div className="overlay">
                <div className="container">
                    <div className="row d-flex header-area">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <a className="navbar-brand" href="/home">
                                <img
                                    src="assets/images/fav.png"
                                    className="fav d-none d-lg-block d-xl-none"
                                    alt="fav"
                                />
                                <img
                                    src="assets/images/logo.png"
                                    className="logo d-block d-lg-none d-xl-block"
                                    alt="logo"
                                />
                            </a>
                            <button
                                className="navbar-toggler collapsed"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbar-content"
                            >
                                <i className="fas fa-bars" />
                            </button>
                            <div
                                className="collapse navbar-collapse justify-content-between"
                                id="navbar-content"
                            >
                                <div className="right-area header-action d-flex align-items-center max-un">
                                    <button
                                        type="button"
                                        className="login"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginMod"
                                    >
                                        Sign In
                                    </button>
                                    <button
                                        type="button"
                                        className="cmn-btn reg"
                                        data-bs-toggle="modal"
                                        data-bs-target="#loginMod"
                                    >
                                        Sign Up
                                    </button>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
        {/* header-section end */}
      {/* Login Registration start */}
      <div className="log-reg">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="modal fade" id="loginMod">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header justify-content-center">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      />
                    </div>
                    <ul className="nav log-reg-btn d-none justify-content-around">
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link"
                          id="regArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#regArea"
                          type="button"
                          role="tab"
                          aria-controls="regArea"
                          aria-selected="false"
                        >
                          SIGN UP
                        </button>
                      </li>
                      <li className="bottom-area" role="presentation">
                        <button
                          className="nav-link active"
                          id="loginArea-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#loginArea"
                          type="button"
                          role="tab"
                          aria-controls="loginArea"
                          aria-selected="true"
                        >
                          LOGIN
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="loginArea"
                        role="tabpanel"
                        aria-labelledby="loginArea-tab"
                      >
                        <div className="login-reg-content">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h3 className="title">Welcome Back</h3>
                                  <p>
                                    Not a member ?{" "}
                                    <a
                                      href="javascript:void(0)"
                                      className="reg-btn"
                                    >
                                      Register
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="checkbox-item d-flex justify-content-between">
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>Remember me</span>
                                      </span>
                                    </span>
                                  </label>
                                  <a href="javascript:void(0)">Forget Password</a>
                                </div>
                                <div className="btn-area text-center">
                                  <a
                                    href="javascript:void(0)"
                                    className="cmn-btn mt-4"
                                  >
                                    Login
                                    <i className="icon-d-right-arrow-2" />
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="regArea"
                        role="tabpanel"
                        aria-labelledby="regArea-tab"
                      >
                        <div className="login-reg-content regMode">
                          <div className="modal-body">
                            <div className="form-area">
                              <form action="#">
                                <div className="section-text text-center">
                                  <h5 className="sub-title">
                                    Ready to get started?
                                  </h5>
                                  <h3 className="title">
                                    Welcome Bonus +50% SC!
                                  </h3>
                                  <p>
                                    Already have an account?{" "}
                                    <a
                                      href="javascript:void(0)"
                                      className="log-btn"
                                    >
                                      Login
                                    </a>
                                  </p>
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="First Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Last Name" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Phone Number" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input
                                    type="date"
                                    placeholder="Date of Birth"
                                  />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/email-icon.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Email" />
                                </div>
                                <div className="input-area d-flex align-items-center">
                                  <img
                                    src="assets/images/icon/security.png"
                                    alt="icon"
                                  />
                                  <input type="text" placeholder="Password" />
                                </div>
                                <div className="sidebar-single-item">
                                  <label className="checkbox-single d-flex">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input
                                          type="checkbox"
                                          defaultChecked="checked"
                                        />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I am at least 18 years of age and I have
                                          read, accepted and agreed to the{" "}
                                          <u>
                                            <a href="javascript:void(0)">
                                              Privacy Policy
                                            </a>
                                            , Bonus T&amp;C,{" "}
                                            <a href="javascript:void(0)">
                                              Terms and Conditions
                                            </a>
                                          </u>
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <label className="checkbox-single d-flex align-items-center">
                                    <span className="content-area">
                                      <span className="checkbox-area d-flex">
                                        <input type="checkbox" />
                                        <span className="checkmark" />
                                      </span>
                                      <span className="item-title d-flex align-items-center">
                                        <span>
                                          I want to receive promotions by e-mail.
                                        </span>
                                      </span>
                                    </span>
                                  </label>
                                  <div className="btn-area text-center">
                                    <a
                                      href="javascript:void(0)"
                                      className="cmn-btn mt-4"
                                    >
                                      Get Started Now
                                      <i className="icon-d-right-arrow-2" />
                                    </a>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Login Registration end */}
      {/* Banner Section start */}
      <section className="banner-section inner-banner about-us">
        <div className="overlay">
          <div className="banner-content">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-10">
                  <div className="main-content">
                    <h2>Terms Conditions</h2>
                    <div className="breadcrumb-area">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb d-flex align-items-center">
                          <li className="breadcrumb-item">
                            <a href="index.html">Home</a>
                          </li>
                          <li className="breadcrumb-item">
                            <a href="javascript:void(0)">Pages</a>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Terms Conditions
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Banner Section end */}
      {/* Terms Conditions In start */}
      <section className="privacy-content terms">
        <div className="overlay pt-120 pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <div className="top-wrapper">
                  <h1>TERMS OF SERVICE</h1>
                  <p>Date of Last Revision: February 28, 2023</p>
                  <p>Welcome to Tropic Gamers!</p>
                  <p>
                    Tropic Gamers LLC (“Tropic Gamers,” “we,” “us,” or “our”)
                    provides our metaverse platform (described below) and related
                    content to you through our website(s) located at
                    https://www.tropix.vip (the “Site”) and through our related
                    technologies (the game and the Site, including any updated or
                    new features, functionality and technology, collectively, the
                    “Platform”). All access and use of the Platform is subject to
                    the terms and conditions contained in these Terms of Service
                    (as amended from time to time, these “Terms of Service”). By
                    accessing, browsing, or otherwise using the Site or any other
                    aspect of the Service, you acknowledge that you have read,
                    understood, and agree to be bound by these Terms of Service.
                    If you do not accept the terms and conditions of these Terms
                    of Service, you will not access, browse, or otherwise use the
                    Service.
                  </p>
                  <p>
                    We reserve the right, at our sole discretion, to change or
                    modify portions of these Terms of Service at any time. If we
                    do this, we will post the changes on this page and will
                    indicate at the top of this page the date these Terms of
                    Service were last revised. You may read a current, effective
                    copy of these Terms of Service by visiting the “Terms of
                    Service” link on the Site located at https://www.tropix.vip.
                    We will also notify you of any material changes, either
                    through the Service user interface, a pop-up notice or through
                    other reasonable means. Your continued use of the Service
                    after the date any such changes become effective constitutes
                    your acceptance of the new Terms of Service. You should
                    periodically visit this page to review the current Terms of
                    Service so you are aware of any revisions. If you do not agree
                    to abide by these or any future Terms of Service, you will not
                    access, browse, or use (or continue to access, browse, or use)
                    the Platform.
                  </p>
                  <h2>
                    PLEASE READ THESE TERMS OF SERVICE CAREFULLY, AS THEY CONTAIN
                    AN AGREEMENT TO ARBITRATE AND OTHER IMPORTANT INFORMATION
                    REGARDING YOUR LEGAL RIGHTS, REMEDIES, AND OBLIGATIONS.
                  </h2>
                  <p>
                    THE AGREEMENT TO ARBITRATE REQUIRES (WITH LIMITED EXCEPTION)
                    THAT YOU SUBMIT CLAIMS YOU HAVE AGAINST US TO BINDING AND
                    FINAL ARBITRATION, AND FURTHER (1) YOU WILL ONLY BE PERMITTED
                    TO PURSUE CLAIMS AGAINST TROPIC GAMERS ON AN INDIVIDUAL BASIS,
                    NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
                    REPRESENTATIVE ACTION OR PROCEEDING, (2) YOU WILL ONLY BE
                    PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
                    DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY
                    NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST US RESOLVED BY
                    A JURY OR IN A COURT OF LAW.
                  </p>
                  <h2>Your Privacy</h2>
                  <p>
                    At Tropic Gamers, we respect the privacy of our users. For
                    more information please see our Privacy Policy, located at{" "}
                    <a href="https://www.tropix.vip/privacy">
                      https://www.tropix.vip/privacy
                    </a>{" "}
                    (the “Privacy Policy”). By using the Platform, you consent to
                    our collection, use and disclosure of personal data and other
                    data as outlined therein.
                  </p>
                  <h2>Additional Terms</h2>
                  <p>
                    In addition, when using certain features through the Platform,
                    you will be subject to any additional terms applicable to such
                    features that may be posted on or within the Platform from
                    time to time, including but not limited to our Code of Conduct
                    available at [HYPERLINK]. All such terms are hereby
                    incorporated by reference into these Terms of Service.
                  </p>
                  <h2>What is Tropic Gamers?</h2>
                  <p>
                    Tropic Gamers is a social sweepstakes platform that allows its
                    users to engage in real-time virtual experiences, and is
                    subject to the terms and conditions set forth below.
                  </p>
                  <h2>How do I use Tropic Gamers?</h2>
                  <h3>Your Registration Obligations</h3>
                  <p>
                    You may be required to register with Tropic Gamers or provide
                    information about yourself (e.g., name and email address and
                    any information needed to comply with "KYC") in order to
                    access and use certain features of the Platform. If you choose
                    to register for the Platform, you agree to provide and
                    maintain true, accurate, current, and complete information
                    about yourself as prompted by the Platform’s registration
                    form. Registration data and certain other information about
                    you are governed by our Privacy Policy. If you are under 18
                    years of age, you are not authorized to use the Platform.
                  </p>
                  <h3>Member Account, Password and Security</h3>
                  <p>
                    You are solely responsible for maintaining the confidentiality
                    of your password and account details, if any, and are fully
                    responsible for any and all activities that occur under your
                    password or account. You agree to (a) immediately notify
                    Tropic Gamers of any unauthorized use of your password or
                    account or any other breach of security, and (b) ensure that
                    you exit from your account at the end of each session when
                    accessing the Platform. Tropic Gamers will not be liable for
                    any loss or damage arising from your failure to comply with
                    this paragraph.
                  </p>
                  <h3>Modifications to Platform</h3>
                  <p>
                    Tropic Gamers reserves the right to modify or discontinue,
                    temporarily or permanently, the Platform (or any part thereof)
                    with or without notice. You agree that Tropic Gamers will not
                    be liable to you or to any third party for any modification,
                    suspension or discontinuance of the Platform.
                  </p>
                  <h3>General Practices Regarding Use and Storage</h3>
                  <p>
                    You acknowledge that Tropic Gamers may establish general
                    practices and limits concerning use of the Platform, including
                    the maximum period of time that data or other content will be
                    retained by the Platform and the maximum storage space that
                    will be allotted on Tropic Gamers’ or its third-party service
                    providers’ servers on your behalf. You agree that Tropic
                    Gamers has no responsibility or liability for the deletion or
                    failure to store any data or other content maintained or
                    uploaded by the Platform. You acknowledge that Tropic Gamers
                    reserves the right to terminate accounts that are inactive for
                    an extended period of time. You further acknowledge that
                    Tropic Gamers reserves the right to change these general
                    practices and limits at any time, in its sole discretion, with
                    or without notice.
                  </p>
                  <h2>Payments</h2>
                  <h3>Fees</h3>
                  <p>
                    Upon becoming a registered user, you may be prompted, or have
                    the ability to, fund your account or purchase Tropix Coins or
                    other In-Platform Digital Assets (as defined below) using fiat
                    or blockchain based currencies approved by Tropic Gamers. All
                    amounts you pay to make purchases through the Platform are
                    non-refundable and non-cancellable.
                  </p>
                  <p>
                    When you provide payment information to Tropic Gamers or to
                    one of its payment processors, you represent to Tropic Gamers
                    that you are the authorized user of the card, PIN, key or
                    account associated with that payment, and you authorize Tropic
                    Gamers to charge your credit card or to process your payment
                    with the chosen third-party payment processor for any fees
                    incurred by you.
                  </p>
                  <h3>Taxes</h3>
                  <p>
                    If your use of the Platform or any purchases you make is
                    subject to any type of use or sales tax, then Tropic Gamers
                    may also charge you for those taxes, in addition to the
                    displayed fees. All fees in the European Union and the United
                    Kingdom may include the EU or UK VAT (“VAT“) tax. VAT amounts
                    collected by Tropic Gamers reflect VAT due on the value of any
                    purchases that you make through the Platform.
                  </p>
                  <h3>Additional Responsibilities</h3>
                  <p>
                    As the account holder, you are responsible for all charges
                    incurred, including applicable taxes, and all orders or
                    purchases made by you or anyone that uses your account,
                    including your family or friends. If you cancel your account,
                    Tropic Gamers reserves the right to collect fees, surcharges
                    or costs incurred before cancellation.
                  </p>
                  <p>
                    You agree that you will not use IP proxying or other methods
                    to disguise the place of your residence, whether to circumvent
                    geographical restrictions on game content, to order or
                    purchase at pricing not applicable to your geography, or for
                    any other purpose. If you do this, Tropic Gamers may terminate
                    your access to your account.
                  </p>
                  <h3>Tropix Coin and In-Platform Digital Assets</h3>
                  <p>
                    As part of your use of the Platform, you may purchase, earn
                    (such as by completing certain tasks) or otherwise obtain
                    Tropix Coins, a digital coin that may be used only on the
                    Platform. You may exchange your Tropix Coin for in-platform
                    items, experiences or other digital assets (collectively,
                    “In-Platform Digital Assets”) that may be used or traded,
                    subject to these Terms of Service or additional applicable
                    terms. In some cases, In-Platform Digital Assets may also be
                    purchased directly through the Platform via payments. Tropic
                    Gamers does not offer any user the ability to exchange one
                    form of currency for any other form of currency (fiat or
                    digital).
                  </p>
                  <p>
                    We may limit, modify, revalue, or make Tropix Coin more or
                    less common, valuable, effective, or functional and may also
                    change, at any time, potential In-Platform Digital Assets on
                    the Platform or the requirements to earn such In-Platform
                    Digital Assets. In-Platform Digital Assets may have limited
                    supplies or may be offered for a limited period. We may, in
                    our sole discretion, substitute In-Platform Digital Assets
                    with other In-Platform Digital Assets of equal or greater
                    value. You agree to comply with all requirements associated
                    with In-Platform Digital Assets, including any third party
                    terms that may apply to the redemption, activation, or use of
                    such In-Platform Digital Assets. Tropix Coins and In-Platform
                    Digital Assets are intended to be redeemed only on the
                    Platform. You are solely responsible for redeeming any Tropix
                    Coin or In-Platform Digital Assets within the time specified
                    in your account or on the Platform, and we are not responsible
                    for your inability to use or derive benefit from any Tropix
                    Coin or In-Platform Digital Assets. You are solely responsible
                    for paying any applicable taxes related to Tropix Coin or
                    In-Platform Digital Assets that you acquire. No credit or
                    reversal of any Tropix Coin or In-Platform Digital Assets will
                    be provided once the Tropix Coin or In-Platform Digital Assets
                    are redeemed. Tropix Coins have no cash value and cannot be
                    redeemed for cash, transferred to a third party, or to make
                    purchases from any other party.
                  </p>
                  <p>
                    The use or transfer of In-Platform Digital is prohibited
                    except where expressly authorized in the Platform. Other than
                    as expressly authorized in the Platform, you shall not sell,
                    purchase, redeem or otherwise transfer In-Platform Digital
                    Assets to any person or entity or attempt any of the
                    aforesaid, including but not limited to Tropic Gamers, another
                    user or any third party.
                  </p>
                  <h3>Trading In-Platform Digital Assets</h3>
                  <p>
                    Tropic Gamers permits you to trade certain In-Platform Digital
                    Assets through the Platform. In-Platform Digital Assets may be
                    traded for other In-Platform Digital Assets or a combination
                    of Tropix Coins and In-Platform Digital Assets. When you
                    engage in a trade through the Platform, Tropic Gamers is
                    entitled to a fee equal to 30% of the total amount of Tropix
                    Coins exchanged in the transaction. If no Tropix Coins are
                    exchanged as part of the trade, Tropic Gamers will not receive
                    any fee in connection with the transaction.
                  </p>
                  <h3>Additional Limitations</h3>
                  <p>
                    ALL PURCHASES AND REDEMPTIONS OF IN-GAME DIGITAL ASSETS MADE
                    THROUGH THE SERVICE ARE FINAL AND NON-REFUNDABLE.
                  </p>
                  <p>
                    The provision of In-Platform Digital Assets is strictly for
                    your personal, non-commercial use within the Platform. This is
                    a service provided by Tropic Gamers that commences immediately
                    upon acceptance by Tropic Gamers of your purchase.
                  </p>
                  <p>
                    You agree to pay all fees and applicable taxes incurred by you
                    or anyone using an Account registered to you. Tropic Gamers
                    may revise the pricing for the In-Platform Digital Assets
                    offered through the Platform at any time. YOU ACKNOWLEDGE THAT
                    TROPIC GAMERS IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY
                    REASON, AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER
                    COMPENSATION FOR UNUSED IN-GAME DIGITAL WHEN AN ACCOUNT IS
                    CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.
                  </p>
                  <p>
                    In-Platform Digital Assets do not represent any stored value,
                    have no monetary value, and do not constitute legal currency
                    of any type.
                  </p>
                  <h2>Mobile Services</h2>
                  <h3>Mobile Services</h3>
                  <p>
                    The Platform includes certain applications or services that
                    are available via a mobile device, including (i) the ability
                    to upload content to the Platform via a mobile device, (ii)
                    the ability to browse the Platform and the Site from a mobile
                    device, and (iii) the ability to access certain features and
                    content through mobile applications (collectively, the “Mobile
                    Services”). To the extent you access the Platform through a
                    mobile device, your wireless service carrier’s standard
                    charges, data rates, and other fees may apply. In addition,
                    downloading, installing, or using certain Mobile Services may
                    be prohibited or restricted by your carrier, and not all
                    Mobile Services may work with all carriers or devices.
                  </p>
                  <h3>Special Notice for International Use; Export Controls</h3>
                  <p>
                    Tropic Gamers is headquartered in the United States. Whether
                    inside or outside of the United States, you are solely
                    responsible for ensuring compliance with the laws of your
                    specific jurisdiction. Software available in connection with
                    the Platform and the transmission of applicable data, if any,
                    is subject to United States export controls. No software may
                    be downloaded from the Platform or otherwise exported or
                    re-exported in violation of U.S. export laws. Downloading,
                    accessing or using the Software or Platform is at your sole
                    risk.
                  </p>
                  <h3>Third-Party Distribution Channels</h3>
                  <p>
                    Tropic Gamers offers software that may be made available
                    through the Apple App Store, the Google Play Store, or other
                    distribution channels (“Distribution Channels”). If you obtain
                    such Software through a Distribution Channel, you may be
                    subject to additional terms of the Distribution Channel. These
                    Terms of Service are between you and us only, and not with the
                    Distribution Channel. To the extent that you utilize any other
                    third-party products and services in connection with your use
                    of the Service, you agree to comply with all applicable terms
                    of any agreement for such third-party products and services.
                  </p>
                  <h3>Apple-Enabled Software</h3>
                  <p>
                    With respect to mobile applications that are made available
                    for your use in connection with an Apple-branded product (the
                    “Apple-Enabled Software”), in addition to the other terms and
                    conditions set forth in these Terms of Service, the following
                    terms and conditions apply:
                  </p>
                  <ul>
                    <li>
                      Tropic Gamers and you acknowledge that these Terms of
                      Service are concluded between Tropic Gamers and you only,
                      and not with Apple Inc. (“Apple”), and that as between
                      Tropic Gamers and Apple, Tropic Gamers, not Apple, is solely
                      responsible for the Apple-Enabled Software and the content
                      thereof.
                    </li>
                    <li>
                      You may not use the Apple-Enabled Software in any manner
                      that is in violation of or inconsistent with the Usage Rules
                      set forth for Apple-Enabled Software in, or otherwise be in
                      conflict with, the Apple Media Services Terms and
                      Conditions.
                    </li>
                    <li>
                      Your license to use the Apple-Enabled Software is limited to
                      a non-transferable license to use the Apple-Enabled Software
                      on an iOS product that you own or control, as permitted by
                      the “Usage Rules” set forth in the Apple Media Services
                      Terms and Conditions, except that such Apple-Enabled
                      Software may be accessed and used by other accounts
                      associated with the purchaser via Apple’s Family Sharing or
                      volume purchasing programs.
                    </li>
                    <li>
                      Apple has no obligation whatsoever to provide any
                      maintenance or support services with respect to the
                      Apple-Enabled Software.
                    </li>
                    <li>
                      Apple is not responsible for any product warranties, whether
                      express or implied by law. In the event of any failure of
                      the Apple-Enabled Software to conform to any applicable
                      warranty, you may notify Apple, and Apple will refund the
                      purchase price for the Apple-Enabled Software, if any, to
                      you; and, to the maximum extent permitted by applicable law,
                      Apple will have no other warranty obligation whatsoever with
                      respect to the Apple-Enabled Software, or any other claims,
                      losses, liabilities, damages, costs, or expenses
                      attributable to any failure to conform to any warranty,
                      which will be Tropic Gamers’ sole responsibility, to the
                      extent it cannot be disclaimed under applicable law.
                    </li>
                    <li>
                      Tropic Gamers and you acknowledge that Tropic Gamers, not
                      Apple, is responsible for addressing any claims of you or
                      any third party relating to the Apple-Enabled Software or
                      your possession and/or use of that Apple-Enabled Software,
                      including: (a) product liability claims; (b) any claim that
                      the Apple-Enabled Software fails to conform to any
                      applicable legal or regulatory requirement; and (c) claims
                      arising under consumer protection, privacy, or similar
                      legislation.
                    </li>
                    <li>
                      In the event of any third-party claim that the Apple-Enabled
                      Software or your possession and use of that Apple-Enabled
                      Software infringes that third party’s intellectual property
                      rights, as between Tropic Gamers and Apple, Tropic Gamers,
                      not Apple, will be solely responsible for the investigation,
                      defense, settlement, and discharge of any such intellectual
                      property infringement claim.
                    </li>
                    <li>
                      You represent and warrant that (a) you are not located in a
                      country that is subject to a U.S. Government embargo, or
                      that has been designated by the U.S. Government as a
                      “terrorist supporting” country; and (b) you are not listed
                      on any U.S. Government list of prohibited or restricted
                      parties.
                    </li>
                    <li>
                      If you have any questions, complaints, or claims with
                      respect to the Apple-Enabled Software, they should be
                      directed to Tropic Gamers as follows:
                      <ul>
                        <li>
                          Email:{" "}
                          <a href="mailto:support@tropix.vip">
                            support@tropix.vip
                          </a>
                        </li>
                        <li>Phone: (949) 444-5464</li>
                        <li>
                          Address: Tropic Gamers LLC, 11411 Southern Highlands
                          Pkwy, Suite #240, Las Vegas, NV 89141
                        </li>
                      </ul>
                    </li>
                    <li>
                      You must comply with applicable third-party terms of
                      agreement when using the Apple-Enabled Software, e.g., your
                      wireless data service agreement.
                    </li>
                    <li>
                      Tropic Gamers and you acknowledge and agree that Apple, and
                      Apple’s subsidiaries, are third-party beneficiaries of these
                      Terms of Service with respect to the Apple-Enabled Software,
                      and that, upon your acceptance of the terms and conditions
                      of these Terms of Service, Apple will have the right (and
                      will be deemed to have accepted the right) to enforce these
                      Terms of Service against you with respect to the
                      Apple-Enabled Software as a third-party beneficiary thereof.
                    </li>
                  </ul>
                  <h3>Google-Sourced Software</h3>
                  <p>
                    The following applies to any mobile applications you download
                    from the Google Play Store (“Google-Sourced Software”): (a)
                    you acknowledge that these Terms of Service are between you
                    and Tropic Gamers only, and not with Google, Inc. (“Google”);
                    (b) your use of Google-Sourced Software must comply with
                    Google’s then-current Google Play Terms of Service; (c) Google
                    is only a provider of Google Play where you obtained the
                    Google-Sourced Software; (d) Tropic Gamers, and not Google, is
                    solely responsible for Tropic Gamers’ Google-Sourced Software;
                    (e) Google has no obligation or liability to you with respect
                    to Google-Sourced Software or these Terms of Service; and (f)
                    you acknowledge and agree that Google is a third-party
                    beneficiary to these Terms of Service as it relates to Tropic
                    Gamers’ Google-Sourced Software.
                  </p>
                  <h2>Conditions of Access and Use</h2>
                  <h3>User Conduct</h3>
                  <p>
                    You are solely responsible for all code, video, images,
                    information, data, text, software, music, sound, photographs,
                    graphics, messages, and other materials (“content”) that you
                    make available to Tropic Gamers, including by uploading,
                    posting, publishing, or displaying (hereinafter,
                    “upload(ing)”) via the Platform or by emailing or otherwise
                    making available to other users of the Platform (collectively,
                    “User Content”). As a user of the Platform you must observe
                    and abide by the Rules of Conduct found here:{" "}
                    <a href="https://tropix.vip/code-of-conduct">
                      https://tropix.vip/code-of-conduct
                    </a>
                    . In addition, you agree to not:
                  </p>
                  <ul>
                    <li>
                      interfere with or disrupt the Platform or servers or
                      networks connected to the Platform, or disobey any
                      requirements, procedures, policies, or regulations of
                      networks connected to the Platform;
                    </li>
                    <li>
                      violate any applicable local, state, national, or
                      international law, or any regulations having the force of
                      law;
                    </li>
                    <li>
                      impersonate any person or entity, or falsely state or
                      otherwise misrepresent your affiliation with a person or
                      entity;
                    </li>
                    <li>
                      solicit personal information from anyone under the age of
                      18;
                    </li>
                    <li>
                      harvest or collect email addresses or other contact
                      information of other users from the Platform by electronic
                      or other means for the purposes of sending unsolicited
                      emails or other unsolicited communications;
                    </li>
                    <li>
                      advertise or offer to sell or buy any goods or services for
                      any business purpose that is not specifically authorized;
                    </li>
                    <li>
                      further or promote any criminal activity or enterprise or
                      provide instructional information about illegal activities;
                    </li>
                    <li>
                      obtain or attempt to access or otherwise obtain any content
                      or information through any means not intentionally made
                      available or provided for through the Platform;
                    </li>
                    <li>
                      circumvent, remove, alter, deactivate, degrade, or thwart
                      any of the content protections in or geographic restrictions
                      on any content (including Platform Content (as defined
                      below)) available on or through the Platform, including
                      through the use of virtual private networks; or
                    </li>
                    <li>
                      engage in or use any data mining, robots, scraping, or
                      similar data gathering or extraction methods.
                    </li>
                  </ul>
                  <h2>Intellectual Property Rights</h2>
                  <h3>Platform Content</h3>
                  <p>
                    You acknowledge and agree that the Platform may contain
                    content or features (“Platform Content”) that are protected by
                    copyright, patent, trademark, trade secret, or other
                    proprietary rights and laws. Except as expressly authorized by
                    Tropic Gamers, you agree not to modify, copy, frame, scrape,
                    rent, lease, loan, sell, distribute, or create derivative
                    works based on the Platform or the Platform Content, in whole
                    or in part, except that the foregoing does not apply to your
                    own User Content (as defined below) that you upload to or make
                    available through the Platform in accordance with these Terms
                    of Service. Any use of the Platform or the Platform Content
                    other than as specifically authorized herein is strictly
                    prohibited.
                  </p>
                  <h3>Trademarks</h3>
                  <p>
                    The Tropic Gamers name and logos are trademarks and service
                    marks of Tropic Gamers (collectively the “Tropic Gamers
                    Trademarks”). Other Tropic Gamers, product, and service names
                    and logos used and displayed via the Platform may be
                    trademarks or service marks of their respective owners who may
                    or may not endorse or be affiliated with or connected to
                    Tropic Gamers. Nothing in these Terms of Service or the
                    Platform should be construed as granting, by implication,
                    estoppel, or otherwise, any license or right to use any of
                    Tropic Gamers Trademarks displayed on the Platform, without
                    our prior written permission in each instance. All goodwill
                    generated from the use of Tropic Gamers Trademarks will inure
                    to our exclusive benefit.
                  </p>
                  <h3>Third-Party Material</h3>
                  <p>
                    Under no circumstances will Tropic Gamers be liable in any way
                    for any content or materials of any third parties (including
                    users), including for any errors or omissions in any content,
                    or for any loss or damage of any kind incurred as a result of
                    the use of any such content. You acknowledge that Tropic
                    Gamers does not pre-screen content, but that Tropic Gamers and
                    its designees will have the right (but not the obligation) in
                    their sole discretion to refuse or remove any content that is
                    available via the Platform. Without limiting the foregoing,
                    Tropic Gamers and its designees will have the right to remove
                    any content that violates these Terms of Service or is deemed
                    by Tropic Gamers, in its sole discretion, to be otherwise
                    objectionable. You agree that you must evaluate, and bear all
                    risks associated with, the use of any content, including any
                    reliance on the accuracy, completeness, or usefulness of such
                    content.
                  </p>
                  <h3>User Content</h3>
                  <p>
                    You represent and warrant that you own all right, title and
                    interest in and to such User Content, including all copyrights
                    and rights of publicity contained therein. You hereby grant
                    Tropic Gamers and its affiliated companies, successors, and
                    assigns a non-exclusive, worldwide, royalty-free, fully
                    paid-up, transferable, sublicensable (directly and indirectly
                    through multiple tiers), perpetual, and irrevocable license to
                    copy, display, upload, perform, distribute, store, modify, and
                    otherwise use your User Content in connection with the
                    operation of the Platform and the promotion, advertising or
                    marketing of the foregoing in any form, medium or technology
                    now known or later developed. You assume all risk associated
                    with your User Content and the transmission of your User
                    Content, and you have sole responsibility for the accuracy,
                    quality, legality and appropriateness of your User Content.
                  </p>
                  <h3>Usage Data</h3>
                  <p>
                    You hereby authorize Tropic Gamers and its third-party service
                    providers to derive statistical and usage data relating to
                    your use of the Platform (“Usage Data”). We may use Usage Data
                    for any purpose in accordance with applicable law and our
                    Privacy Policy.
                  </p>
                  <h3>Feedback</h3>
                  <p>
                    Any questions, comments, suggestions, ideas, feedback,
                    reviews, or other information about the Platform
                    (“Submissions”), provided by you to Tropic Gamers are
                    non-confidential and Tropic Gamers will be entitled to the
                    unrestricted use and dissemination of these Submissions for
                    any purpose, commercial or otherwise, without acknowledgment,
                    attribution, or compensation to you.
                  </p>
                  <h3>Required Disclosures</h3>
                  <p>
                    You acknowledge and agree that Tropic Gamers may preserve User
                    Content and may also disclose User Content if required to do
                    so by law or in the good faith belief that such preservation
                    or disclosure is reasonably necessary to: (a) comply with
                    legal process, applicable laws, or government requests; (b)
                    enforce these Terms of Service; (c) respond to claims that any
                    content violates the rights of third parties; or (d) protect
                    the rights, property, or personal safety of Tropic Gamers, its
                    users, or the public. You understand that the technical
                    processing and transmission of the Platform, including your
                    User Content, may involve (i) transmissions over various
                    networks; and (ii) changes to conform and adapt to technical
                    requirements of connecting networks or devices.
                  </p>
                  <h3>Copyright Complaints</h3>
                  <p>
                    Tropic Gamers respects the intellectual property of others,
                    and we ask our users to do the same. If you believe that your
                    work has been copied in a way that constitutes copyright
                    infringement, or that your intellectual property rights have
                    been otherwise violated, you should notify Tropic Gamers of
                    your infringement claim in accordance with the procedure set
                    forth below.
                  </p>
                  <p>
                    Tropic Gamers will process and investigate notices of alleged
                    infringement and will take appropriate actions under the
                    Digital Millennium Copyright Act (“DMCA”) and other applicable
                    intellectual property laws with respect to any alleged or
                    actual infringement. A notification of claimed copyright
                    infringement should be emailed to Tropic Gamers’ Copyright
                    Agent at{" "}
                    <a href="mailto:support@tropix.vip">support@tropix.vip</a>{" "}
                    (Subject line: “DMCA Takedown Request”). You may also contact
                    the Copyright Agent by mail or facsimile at:
                  </p>
                  <p>
                    Tropic Gamers LLC
                    <br />
                    11011 Southern Highlands Pkwy,
                    <br />
                    Suite #200,
                    <br />
                    Las Vegas, NV 89141
                    <br />
                    Phone/FAX: (909) 404-5464
                  </p>
                  <p>
                    To be effective, the notification must be in writing and
                    contain the following information:
                  </p>
                  <ul>
                    <li>
                      a physical or electronic signature of a person authorized to
                      act on behalf of the owner of the copyright or other
                      intellectual property interest that is allegedly infringed;
                    </li>
                    <li>
                      identification of the copyrighted work or other intellectual
                      property that you claim has been infringed, or, if multiple
                      copyrighted works or other intellectual property are covered
                      by a single notification, a representative list of such
                      works or other intellectual property;
                    </li>
                    <li>
                      identification of the content that is claimed to be
                      infringing or to be the subject of infringing activity, and
                      where the content that you claim is infringing is located on
                      the Platform, with enough detail that we may find it on the
                      Platform;
                    </li>
                    <li>your address, telephone number, and email address;</li>
                    <li>
                      a statement by you that you have a good faith belief that
                      the disputed use is not authorized by the copyright or
                      intellectual property owner, its agent, or the law; and
                    </li>
                    <li>
                      a statement by you that the information in your notice is
                      accurate and, under penalty of perjury, that you are the
                      copyright or intellectual property owner or are authorized
                      to act on the behalf of the owner of the copyright or
                      intellectual property that is allegedly infringed.
                    </li>
                  </ul>
                  <h3>Counter-Notice</h3>
                  <p>
                    If you believe that your User Content that was removed (or to
                    which access was disabled) is not infringing, or that you have
                    the authorization from the copyright owner, the copyright
                    owner’s agent, or pursuant to the law, to upload and use the
                    content in your User Content, you may send a written
                    counter-notice containing the following information to the
                    Copyright Agent:
                  </p>
                  <ul>
                    <li>your physical or electronic signature;</li>
                    <li>
                      identification of the content that has been removed or to
                      which access has been disabled and the location at which the
                      content appeared before it was removed or disabled;
                    </li>
                    <li>
                      a statement by you, made under penalty of perjury, that you
                      have a good faith belief that the content was removed or
                      disabled as a result of mistake or a misidentification of
                      the content to be removed or disabled; and
                    </li>
                    <li>
                      your name, address, telephone number, and email address, a
                      statement that you consent to the jurisdiction of the
                      federal court located within the District of Nevada and a
                      statement that you will accept service of process from the
                      person who provided notification of the alleged
                      infringement.
                    </li>
                  </ul>
                  <p>
                    If a counter-notice is received by the Copyright Agent, Tropic
                    Gamers will send a copy of the counter-notice to the original
                    complaining party informing them that Tropic Gamers may
                    replace the removed content or cease disabling it within ten
                    (10) business days. Unless the owner of the applicable
                    copyrighted work or other intellectual property files an
                    action seeking a court order against Tropic Gamers or the
                    user, the removed content may be replaced, or access to it
                    restored, within ten (10) to fourteen (14) business days or
                    more after receipt of the counter-notice, at our sole
                    discretion.
                  </p>
                  <h3>Repeat Infringer Policy</h3>
                  <p>
                    In accordance with the DMCA and other applicable law, Tropic
                    Gamers has adopted a policy of terminating, in appropriate
                    circumstances and at Tropic Gamers’ sole discretion, the
                    accounts of users who are deemed to be repeat infringers.
                    Tropic Gamers may also at its sole discretion limit access to
                    the Platform and/or terminate the accounts of any users who
                    infringe any intellectual property rights of others, whether
                    or not there is any repeat infringement.
                  </p>
                  <h2>Third-Party Services and Websites</h2>
                  <p>
                    The Platform may provide links or other access to services,
                    sites, technology, and resources that are provided or
                    otherwise made available by third parties (the “Third-Party
                    Services”). Additionally, you may enable or log in to the
                    Service via various online Third-Party Services, such as
                    social media and social networking services like Discord,
                    Facebook, or Twitter. Your access and use of the Third-Party
                    Services may also be subject to additional terms and
                    conditions, privacy policies, or other agreements with such
                    third party, and you may be required to authenticate to or
                    create separate accounts to use Third-Party Services on the
                    websites or via the technology platforms of their respective
                    providers. Some Third-Party Services will provide us with
                    access to certain information that you have provided to third
                    parties, including through such Third-Party Services, and we
                    will use, store and disclose such information in accordance
                    with our Privacy Policy. For more information about the
                    implications of activating Third-Party Services and our use,
                    storage and disclosure of information related to you and your
                    use of such Third-Party Services within the Service, please
                    see our Privacy Policy. Tropic Gamers has no control over and
                    is not responsible for such Third-Party Services, including
                    for the accuracy, availability, reliability, or completeness
                    of information shared by or available through Third-Party
                    Services, or on the privacy practices of Third-Party Services.
                    We encourage you to review the privacy policies of the third
                    parties providing Third-Party Services prior to using such
                    services. You, and not Tropic Gamers, will be responsible for
                    any and all costs and charges associated with your use of any
                    Third-Party Services. Tropic Gamers enables these Third-Party
                    Services merely as a convenience and the integration or
                    inclusion of such Third-Party Services does not imply an
                    endorsement or recommendation. Any dealings you have with
                    third parties while using the Platform are between you and the
                    third party. Tropic Gamers will not be responsible or liable,
                    directly or indirectly, for any damage or loss caused or
                    alleged to be caused by or in connection with use of or
                    reliance on any Third-Party Services.
                  </p>
                  <h2>Indemnification</h2>
                  <p>
                    To the extent permitted under applicable law, you agree to
                    defend, indemnify, and hold harmless Tropic Gamers, its
                    affiliates, and its and their respective officers, employees,
                    directors, service providers, licensors, and agents
                    (collectively, the “Tropic Gamers Parties”) from any and all
                    losses, damages, expenses, including reasonable attorneys’
                    fees, rights, claims, actions of any kind, and injury
                    (including death) arising out of or relating to your use of
                    the Platform, any User Content, your connection to the
                    Platform, your violation of these Terms of Service, or your
                    violation of any rights of another. Tropic Gamers will provide
                    notice to you of any such claim, suit, or proceeding. Tropic
                    Gamers reserves the right to assume the exclusive defense and
                    control of any matter which is subject to indemnification
                    under this section, and you agree to cooperate with any
                    reasonable requests assisting Tropic Gamers’ defense of such
                    matter. You may not settle or compromise any claim against the
                    Tropic Gamers Parties without Tropic Gamers’ written consent.
                  </p>
                  <h2>Disclaimer of Warranties</h2>
                  <p>
                    YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS
                    PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. THE TROPIC
                    GAMERS PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND,
                    WHETHER EXPRESS, IMPLIED OR STATUTORY, INCLUDING THE IMPLIED
                    WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                    PURPOSE, TITLE, AND NON-INFRINGEMENT.
                  </p>
                  <p>
                    THE TROPIC GAMERS PARTIES MAKE NO WARRANTY THAT (A) THE
                    SERVICE WILL MEET YOUR REQUIREMENTS; (B) THE SERVICE WILL BE
                    UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (C) THE RESULTS
                    THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL BE
                    ACCURATE OR RELIABLE; OR (D) THE QUALITY OF ANY PRODUCTS,
                    SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED
                    BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.
                  </p>
                  <h2>Limitation of Liability</h2>
                  <p>
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE TROPIC GAMERS
                    PARTIES WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                    SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS
                    OF PROFITS INCLUDING DAMAGES FOR LOSS OF GOODWILL, USE, OR
                    DATA OR OTHER INTANGIBLE LOSSES (EVEN IF THE TROPIC GAMERS
                    PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES),
                    WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY,
                    OR OTHERWISE, RESULTING FROM: (A) THE USE OR THE INABILITY TO
                    USE THE SERVICE; (B) THE COST OF PROCUREMENT OF SUBSTITUTE
                    GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA,
                    INFORMATION, OR SERVICES PURCHASED OR OBTAINED OR MESSAGES
                    RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE
                    SERVICE; (C) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR
                    TRANSMISSIONS OR DATA; (D) STATEMENTS OR CONDUCT OF ANY THIRD
                    PARTY ON THE SERVICE; OR (E) ANY OTHER MATTER RELATING TO THE
                    SERVICE. IN NO EVENT WILL THE TROPIC GAMERS PARTIES’ TOTAL
                    LIABILITY TO YOU FOR ALL DAMAGES, LOSSES, OR CAUSES OF ACTION
                    EXCEED THE AMOUNT YOU HAVE PAID TROPIC GAMERS IN THE LAST SIX
                    (6) MONTHS, OR, IF GREATER, ONE HUNDRED DOLLARS ($100).
                  </p>
                  <p>
                    SOME JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF
                    CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY
                    FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF
                    THE ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR
                    BE ENFORCEABLE WITH RESPECT TO YOU. IF YOU ARE DISSATISFIED
                    WITH ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF
                    SERVICE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE
                    OF THE SERVICE.
                  </p>
                  <p>
                    IF YOU ARE A USER FROM NEW JERSEY, THE FOREGOING SECTIONS
                    TITLED “INDEMNIFICATION”, “DISCLAIMER OF WARRANTIES” AND
                    “LIMITATION OF LIABILITY” ARE INTENDED TO BE ONLY AS BROAD AS
                    IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF ANY
                    PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS
                    OF THE STATE OF NEW JERSEY, THE INVALIDITY OF SUCH PORTION
                    WILL NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE
                    APPLICABLE SECTIONS.
                  </p>
                  <h2>Dispute Resolution By Binding Arbitration</h2>
                  <p>
                    PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.
                  </p>
                  <h3>Agreement to Arbitrate</h3>
                  <p>
                    This Dispute Resolution by Binding Arbitration section is
                    referred to in these Terms of Service as the “Arbitration
                    Agreement.” You agree that any and all disputes or claims that
                    have arisen or may arise between you and Company, whether
                    arising out of or relating to these Terms of Service
                    (including any alleged breach thereof), the Service, any
                    advertising, or any aspect of the relationship or transactions
                    between us, will be resolved exclusively through final and
                    binding arbitration, rather than a court, in accordance with
                    the terms of this Arbitration Agreement, except that you may
                    assert individual claims in small claims court, if your claims
                    qualify. Further, this Arbitration Agreement does not preclude
                    you from bringing issues to the attention of federal, state,
                    or local agencies, and such agencies can, if the law allows,
                    seek relief against us on your behalf. You agree that, by
                    entering into these Terms of Service, you and Company are each
                    waiving the right to a trial by jury or to participate in a
                    class action. Your rights will be determined by a neutral
                    arbitrator, not a judge or jury. The Federal Arbitration Act
                    governs the interpretation and enforcement of this Arbitration
                    Agreement.
                  </p>
                  <h3>
                    Prohibition of Class and Representative Actions and
                    Non-Individualized Relief
                  </h3>
                  <p>
                    YOU AND COMPANY AGREE THAT EACH OF US MAY BRING CLAIMS AGAINST
                    THE OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF
                    OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
                    ACTION OR PROCEEDING. UNLESS BOTH YOU AND COMPANY AGREE
                    OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE
                    THAN ONE PERSON’S OR PARTY’S CLAIMS AND MAY NOT OTHERWISE
                    PRESIDE OVER ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR
                    CLASS PROCEEDING. ALSO, THE ARBITRATOR MAY AWARD RELIEF
                    (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) ONLY
                    IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO
                    THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT
                    PARTY’S INDIVIDUAL CLAIM(S), EXCEPT THAT YOU MAY PURSUE A
                    CLAIM FOR AND THE ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE
                    RELIEF UNDER APPLICABLE LAW TO THE EXTENT REQUIRED FOR THE
                    ENFORCEABILITY OF THIS PROVISION.
                  </p>
                  <h3>Pre-Arbitration Dispute Resolution</h3>
                  <p>
                    Company is always interested in resolving disputes amicably
                    and efficiently, and most customer concerns can be resolved
                    quickly and to the customer’s satisfaction by emailing
                    customer support at{" "}
                    <a href="mailto:support@tropix.vip">support@tropix.vip</a>. If
                    such efforts prove unsuccessful, a party who intends to seek
                    arbitration must first send to the other, by certified mail, a
                    written Notice of Dispute (“Notice”). The Notice to Company
                    should be sent to Tropic Gamers LLC, 11411 Southern Highlands
                    Pkwy, Suite #240, Las Vegas, NV 89141 (“Notice Address”). The
                    Notice must (i) describe the nature and basis of the claim or
                    dispute and (ii) set forth the specific relief sought. If
                    Company and you do not resolve the claim within sixty (60)
                    calendar days after the Notice is received, you or Company may
                    commence an arbitration proceeding. During the arbitration,
                    the amount of any settlement offer made by Company or you will
                    not be disclosed to the arbitrator until after the arbitrator
                    determines the amount, if any, to which you or Company is
                    entitled.
                  </p>
                  <h3>Arbitration Procedures</h3>
                  <p>
                    Arbitration will be conducted by a neutral arbitrator in
                    accordance with the American Arbitration Association’s (“AAA”)
                    rules and procedures, including the AAA’s Consumer Arbitration
                    Rules (collectively, the “AAA Rules”), as modified by this
                    Arbitration Agreement. For information on the AAA, please
                    visit its website,{" "}
                    <a href="https://www.adr.org">https://www.adr.org</a>.
                    Information about the AAA Rules and fees for consumer disputes
                    can be found at the AAA’s consumer arbitration page,{" "}
                    <a href="https://www.adr.org/consumer">
                      https://www.adr.org/consumer
                    </a>
                    . If there is any inconsistency between any term of the AAA
                    Rules and any term of this Arbitration Agreement, the
                    applicable terms of this Arbitration Agreement will control
                    unless the arbitrator determines that the application of the
                    inconsistent Arbitration Agreement terms would not result in a
                    fundamentally fair arbitration. The arbitrator must also
                    follow the provisions of these Terms of Service as a court
                    would. All issues are for the arbitrator to decide, including
                    issues relating to the scope, enforceability, and
                    arbitrability of this Arbitration Agreement. Although
                    arbitration proceedings are usually simpler and more
                    streamlined than trials and other judicial proceedings, the
                    arbitrator can award the same damages and relief on an
                    individual basis that a court can award to an individual under
                    these Terms of Service and applicable law. Decisions by the
                    arbitrator are enforceable in court and may be overturned by a
                    court only for very limited reasons.
                  </p>
                  <p>
                    Unless Company and you agree otherwise, any arbitration
                    hearings will take place in a reasonably convenient location
                    for both parties with due consideration of their ability to
                    travel and other pertinent circumstances. If the parties are
                    unable to agree on a location, the determination will be made
                    by AAA. If your claim is for $10,000 or less, Company agrees
                    that you may choose whether the arbitration will be conducted
                    solely on the basis of documents submitted to the arbitrator,
                    through a telephonic hearing, or by an in-person hearing as
                    established by the AAA Rules. If your claim exceeds $10,000,
                    the right to a hearing will be determined by the AAA Rules.
                    Regardless of the manner in which the arbitration is
                    conducted, the arbitrator will issue a reasoned written
                    decision sufficient to explain the essential findings and
                    conclusions on which the award is based.
                  </p>
                  <h3>Costs of Arbitration</h3>
                  <p>
                    Payment of all filing, administration, and arbitrator fees
                    (collectively, the “Arbitration Fees”) will be governed by the
                    AAA Rules, unless otherwise provided in this Arbitration
                    Agreement. To the extent any Arbitration Fees are not
                    specifically allocated to either Company or you under the AAA
                    Rules, Company and you shall split them equally; provided that
                    if you are able to demonstrate to the arbitrator that you are
                    economically unable to pay your portion of such Arbitration
                    Fees or if the arbitrator otherwise determines for any reason
                    that you should not be required to pay your portion of any
                    Arbitration Fees, Company will pay your portion of such fees.
                    In addition, if you demonstrate to the arbitrator that the
                    costs of arbitration will be prohibitive as compared to the
                    costs of litigation, Company will pay as much of the
                    Arbitration Fees as the arbitrator deems necessary to prevent
                    the arbitration from being cost-prohibitive. Any payment of
                    attorneys’ fees will be governed by the AAA Rules.
                  </p>
                  <h3>Confidentiality</h3>
                  <p>
                    All aspects of the arbitration proceeding, and any ruling,
                    decision, or award by the arbitrator, will be strictly
                    confidential for the benefit of all parties.
                  </p>
                  <h3>Severability</h3>
                  <p>
                    If a court or the arbitrator decides that any term or
                    provision of this Arbitration Agreement (other than the
                    subsection (b) above titled “Prohibition of Class and
                    Representative Actions and Non-Individualized Relief” above)
                    is invalid or unenforceable, the parties agree to replace such
                    term or provision with a term or provision that is valid and
                    enforceable and that comes closest to expressing the intention
                    of the invalid or unenforceable term or provision, and this
                    Arbitration Agreement will be enforceable as so modified. If a
                    court or the arbitrator decides that any of the provisions of
                    subsection (b) above titled “Prohibition of Class and
                    Representative Actions and Non-Individualized Relief” are
                    invalid or unenforceable, then the entirety of this
                    Arbitration Agreement will be null and void, unless such
                    provisions are deemed to be invalid or unenforceable solely
                    with respect to claims for public injunctive relief. The
                    remainder of these Terms of Service will continue to apply.
                  </p>
                  <h3>Future Changes to Arbitration Agreement</h3>
                  <p>
                    Notwithstanding any provision in these Terms of Service to the
                    contrary, Company agrees that if it makes any future change to
                    this Arbitration Agreement (other than a change to the Notice
                    Address) while you are a user of the Service, you may reject
                    any such change by sending Company written notice within
                    thirty (30) calendar days of the change to the Notice Address
                    provided above. By rejecting any future change, you are
                    agreeing that you will arbitrate any dispute between us in
                    accordance with the language of this Arbitration Agreement as
                    of the date you first accepted these Terms of Service (or
                    accepted any subsequent changes to these Terms of Service).
                  </p>
                  <h2>Termination</h2>
                  <p>
                    You agree that Tropic Gamers, in its sole discretion, may
                    suspend or terminate your account (or any part thereof) or use
                    of the Platform and remove and discard any content within the
                    Platform, for any reason, including for lack of use or if
                    Tropic Gamers believes that you have violated or acted
                    inconsistently with the letter or spirit of these Terms of
                    Service. Any suspected fraudulent, abusive, or illegal
                    activity that may be grounds for termination of your use of
                    the Platform, may be referred to appropriate law enforcement
                    authorities. Tropic Gamers may also in its sole discretion and
                    at any time discontinue providing the Platform, or any part
                    thereof, with or without notice. You agree that any
                    termination of your access to the Platform under any provision
                    of these Terms of Service may be effected without prior
                    notice, and acknowledge and agree that Tropic Gamers may
                    immediately deactivate or delete your account and all related
                    information and files in your account and/or bar any further
                    access to such files or the Platform. Further, you agree that
                    Tropic Gamers will not be liable to you or any third party for
                    any termination of your access to the Platform, or revoking
                    any benefits, privileges, earned In-Platform Digital Assets
                    and/or purchases associated with your Account.
                  </p>
                  <h2>User Disputes</h2>
                  <p>
                    You agree that you are solely responsible for your
                    interactions with any other user in connection with the
                    Platform, and Tropic Gamers will have no liability or
                    responsibility with respect thereto. Tropic Gamers reserves
                    the right, but has no obligation, to become involved in any
                    way with disputes between you and any other user of the
                    Platform.
                  </p>
                  <h2>General</h2>
                  <p>
                    These Terms of Service (together with the terms incorporated
                    by reference herein) constitute the entire agreement between
                    you and Tropic Gamers governing your access and use of the
                    Service, and supersede any prior agreements between you and
                    Tropic Gamers with respect to the Platform. You also may be
                    subject to additional terms and conditions that may apply when
                    you use Third-Party Service, third-party content or
                    third-party software. These Terms of Service will be governed
                    by the laws of the State of Nevada without regard to its
                    conflict of law provisions. With respect to any disputes or
                    claims not subject to arbitration, as set forth above, you and
                    Tropic Gamers submit to the personal and exclusive
                    jurisdiction of the state and federal courts located within
                    Las Vegas, Nevada. The failure of Tropic Gamers to exercise or
                    enforce any right or provision of these Terms of Service will
                    not constitute a waiver of such right or provision. If any
                    provision of these Terms of Service is found by a court of
                    competent jurisdiction to be invalid, the parties nevertheless
                    agree that the court should endeavor to give effect to the
                    parties’ intentions as reflected in the provision, and the
                    other provisions of these Terms of Service remain in full
                    force and effect. You agree that regardless of any statute or
                    law to the contrary, any claim or cause of action arising out
                    of or related to use of the Platform or these Terms of Service
                    must be filed within one (1) year after such claim or cause of
                    action arose or be forever barred. A printed version of these
                    Terms of Service and of any notice given in electronic form
                    will be admissible in judicial or administrative proceedings
                    based upon or relating to these Terms of Service to the same
                    extent and subject to the same conditions as other business
                    documents and records originally generated and maintained in
                    printed form. You may not assign these Terms of Service
                    without the prior written consent of Tropic Gamers, but Tropic
                    Gamers may assign or transfer these Terms of Service, in whole
                    or in part, without restriction. The section titles in these
                    Terms of Service are for convenience only and have no legal or
                    contractual effect. As used in these Terms of Service, the
                    words “include” and “including,” and variations thereof, will
                    not be deemed to be terms of limitation, but rather will be
                    deemed to be followed by the words “without limitation.”
                    Notices to you may be made via either email or regular mail.
                    The Platform may also provide notices to you of changes to
                    these Terms of Service or other matters by displaying notices
                    or links to notices generally on the Platform. Tropic Gamers
                    will not be in default hereunder by reason of any failure or
                    delay in the performance of its obligations where such failure
                    or delay is due to civil disturbances, riot, epidemic,
                    hostilities, war, terrorist attack, embargo, natural disaster,
                    acts of God, flood, fire, sabotage, fluctuations or
                    unavailability of electrical power, network access or
                    equipment, or any other circumstances or causes beyond Tropic
                    Gamers’ reasonable control.
                  </p>
                  <h2>Notice for California Users</h2>
                  <p>
                    Under California Civil Code Section 1789.3, users of the
                    Platform from California are entitled to the following
                    specific consumer rights notice: The Complaint Assistance Unit
                    of the Division of Consumer Services of the California
                    Department of Consumer Affairs may be contacted (a) via email
                    at <a href="mailto:dca@dca.ca.gov">dca@dca.ca.gov</a>; (b) in
                    writing at: Department of Consumer Affairs, Consumer
                    Information Division, 1625 North Market Blvd., Suite N 112,
                    Sacramento, CA 95834; or (c) by telephone at (800) 952-5210 or
                    (800) 326-2297 (TDD). Sacramento-area consumers may call (916)
                    445-1254 or (916) 928-1227 (TDD).
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Terms Conditions In end */}
      {/* Footer Area Start */}
      <footer className="footer-section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="footer-form">
                <div className="section-area mb-30 text-center">
                  <h4 className="title">To Get Exclusive Benefits</h4>
                </div>
                <form action="#">
                  <div className="form-group input-area d-flex align-items-center">
                    <input type="text" placeholder="Enter your email address" />
                    <button className="btn-area">
                      <img src="assets/images/icon/send-icon.png" alt="icon" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="footer-area pt-120">
            <div className="row">
              <div className="col-xl-12">
                <div className="footer-top d-flex align-items-center justify-content-between">
                  <a href="/home">
                    <img
                      src="assets/images/logo.png"
                      className="logo"
                      alt="logo"
                    />
                  </a>
                  <div className="footer-box">
                    <ul className="footer-link d-flex align-items-center gap-4">
                    <li>
                        <a href="/contact-us">Contact</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/termandconditions">Terms of Service</a>
                      </li>
                      <li>
                        <a href="/contact-us">KYC/AML</a>
                      </li>
                      <li>
                        <a href="/privacy-policy">Sweepstakes Rules</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-7 d-flex justify-content-center justify-content-lg-start order-lg-0 order-1">
                  <div className="copyright text-center">
                    <p>© Tropic Gamers 2023 | All rights reserved.</p>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-5 d-flex justify-content-center justify-content-lg-end">
                  <div className="social">
                    <ul className="footer-link gap-2 d-flex align-items-center">
                      <li>
                        <a href="javascript:void(0)">
                          <i className="fb fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="ins fab fa-instagram" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="tw fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0)">
                          <i className="in fab fa-linkedin-in" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer Area End */}
      {/*==================================================================*/}
    </>
  )
  